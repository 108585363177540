<form name="form" (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" novalidate
    style="padding-left:50px;text-align: center;width: 90%;">
<mat-card>
    <mat-card-title>Add User </mat-card-title>
    <mat-card-subtitle>Personal Info</mat-card-subtitle>
    <div class="row">
        
            <mat-form-field class="form-group" style="padding-right: 50px;" >
                <mat-label for="FirstName">First Name</mat-label>
                <input matInput type="text" class="form-control" name="FirstName" [(ngModel)]="form.FirstName"
                    required #FirstName="ngModel" />
                <mat-error class="alert alert-danger" role="alert" *ngIf="FirstName.errors && f.submitted">
                    First Name is required!
                </mat-error>
            </mat-form-field>
        
            <mat-form-field class="form-group">
                <mat-label for="LastName">Last Name</mat-label>
                <input matInput type="LastName" class="form-control" name="LastName" [(ngModel)]="form.LastName"
                    required #LastName="ngModel" />
                <mat-error class="alert alert-danger" role="alert" *ngIf="LastName.errors && f.submitted">LastName
                    is required
                </mat-error>
            </mat-form-field>
        
    </div>
    <div class="row">
        <mat-form-field class="form-group"  style="padding-right: 50px;">
            <mat-label for="Email">Email Address</mat-label>
            <input matInput type="text" required class="form-control" name="Email" [(ngModel)]="form.Email"
                #Email="ngModel" />
                <mat-error class="alert alert-danger" role="alert" *ngIf="Email.errors && f.submitted">Email
                    is required
                </mat-error>
        </mat-form-field>
        <mat-form-field class="form-group">
            <mat-label for="Password">Password</mat-label>
            <input matInput type="password" required class="form-control" name="Password" [(ngModel)]="form.Password"
                #Password="ngModel" />
                <mat-error class="alert alert-danger" role="alert" *ngIf="Password.errors && f.submitted">Password
                    is required
                </mat-error>
        </mat-form-field>
    </div>
    <div class="row">
        <mat-form-field class="form-group" style="padding-right: 50px;">
            <mat-label for="CompanyName">Company Name</mat-label>
            <input matInput type="text" class="form-control" name="CompanyName" [(ngModel)]="form.CompanyName"
                #CompanyName="ngModel" />
        </mat-form-field>
        <mat-form-field class="form-group">
            <mat-label for="ClientId">Client Id</mat-label>
            <input matInput type="number" required class="form-control" name="ClientId" [(ngModel)]="form.ClientId"
                #ClientId="ngModel" />
                <mat-error class="alert alert-danger" role="alert" *ngIf="ClientId.errors && f.submitted">Client Id
                    is required
                </mat-error>
        </mat-form-field>
    </div>
    <mat-card-subtitle>Address Info</mat-card-subtitle>
    <div class="row">
        <mat-form-field class="form-group" style="padding-right: 50px;">
            <mat-label for="Address">Address</mat-label>
            <input matInput type="text" class="form-control" name="Address" [(ngModel)]="form.Address"
                #Address="ngModel" />
            
        </mat-form-field>
        <mat-form-field class="form-group" >
            <mat-label for="City">City</mat-label>
            <input matInput type="text" class="form-control" name="City" [(ngModel)]="form.City"
                #City="ngModel" />
        </mat-form-field>
    </div>
    <div class="row">
        <mat-form-field class="form-group" style="padding-right: 50px;">
            <mat-label for="State">State</mat-label>
            <input matInput type="text" class="form-control" name="State" [(ngModel)]="form.State"
                #State="ngModel" />
            
        </mat-form-field>
        <mat-form-field class="form-group" >
            <mat-label for="Zip">Zip Code</mat-label>
            <input matInput type="text" class="form-control" name="Zip" [(ngModel)]="form.Zip"
                #Zip="ngModel" />
        </mat-form-field>
    </div>
    <div class="form-group">
        <button mat-raised-button>
            Add User
        </button>
    </div>
</mat-card>
</form>