
<button mat-icon-button (click)="drawer.toggle()" style="margin-top: -60px;" aria-label="Expand Menu" *ngIf="isLoggedIn">
    <mat-icon style="margin-top: -60px;">menu</mat-icon>
</button>
<app-dashboard-header></app-dashboard-header>
<mat-drawer-container class="example-container" style="height: 85%;">
    <mat-drawer #drawer mode="side">
        <div class="row">
           <button mat-button style="width:100%;text-align: left;" (click)="goToLink('dashboard')"> <mat-icon>home</mat-icon>  Dashboard</button>
        </div>
        <div class="row">
            <button mat-button style="width:100%;text-align: left;" (click)="goToLink('search')"><mat-icon>search</mat-icon>  Search Packages</button>
        </div>
        <div class="row">
            <button mat-button style="width:100%;text-align: left;" (click)="goToLink('avoidable')"><mat-icon>savings</mat-icon>  Avoidable Costs</button>
        </div>
        <div class="row">
            <button mat-button style="width:100%;text-align: left;" (click)="goToLink('performance')"><mat-icon>leaderboard</mat-icon>  Performance</button>
        </div>
        <div class="row">
            <button mat-button style="width:100%;text-align: left;" (click)="goToLink('trends')"><mat-icon>trending_up</mat-icon>  Trends</button>
        </div>
        <div class="row">
            <button mat-button style="width:100%;text-align: left;" (click)="goToLink('reportDownload')"><mat-icon>arrow_circle_down</mat-icon>  Report Download</button>
        </div>
        <div class="row">
            <button mat-button style="width:100%;text-align: left;" (click)="goToLink('carrierInvoice')"><mat-icon>view_list</mat-icon>  Carrier Invoice Reports</button>
        </div>
        <div class="row">
            <button mat-button style="width:100%;text-align: left;" (click)="goToLink('invoices')"><mat-icon>fact_check</mat-icon>  Monthly Lamprey Invoices</button>
        </div>
        <div class="row">
            <button mat-button style="width:100%;text-align: left;" (click)="goToLink('profile')"><mat-icon>account_circle</mat-icon>  Profile</button>
        </div>
        <div class="row" *ngIf="userInfo !== undefined && userInfo.UserType == 'ADMIN'">
            <button mat-button style="width:100%;text-align: left;" (click)="goToLink('addnew')"><mat-icon>supervised_user_circle</mat-icon>  Add New</button>
        </div>
        <div class="row">
            <button mat-button style="width:100%;text-align: left;" (click)="logout();drawer.toggle();"><mat-icon>lock_open</mat-icon>  Logout</button>
        </div>
    </mat-drawer>

    <mat-drawer-content>
        
        <div class="container" style="margin:20px; height: 500px;">
            <router-outlet >
                
            </router-outlet>
            
        </div>

        
    </mat-drawer-content>
    
</mat-drawer-container>
<app-dashboard-footer style="position: absolute;bottom:0;width: 100%;min-height: 50px;"></app-dashboard-footer>