<div class="row">
  <mat-card style="margin: 20px;">
    <mat-card-title>FILTERS</mat-card-title>
    <mat-form-field style="margin: 5px;" appearance="fill">
      <mat-label>Start Date</mat-label>
      <input matInput [matDatepicker]="startdate" [(ngModel)]="form.StartDate">
      <mat-datepicker-toggle matSuffix [for]="startdate"></mat-datepicker-toggle>
      <mat-datepicker #startdate></mat-datepicker>
    </mat-form-field>
    <mat-form-field style="margin: 5px;" appearance="fill">
      <mat-label>End Date</mat-label>
      <input matInput [matDatepicker]="enddate" [(ngModel)]="form.EndDate">
      <mat-datepicker-toggle matSuffix [for]="enddate"></mat-datepicker-toggle>
      <mat-datepicker #enddate></mat-datepicker>
    </mat-form-field>
    <div style="display: inline-block;position: relative;text-align:left;margin-left:20px;">
      <button mat-raised-button (click)="updateCosts();">
        Update
      </button>
    </div>
  </mat-card>
</div>
<div fxLayout="row wrap">
    <div fxFlex="50%" fxFlex.md="50%" fxFlex.xs="100%" fxFlex.sm="100%" >
      <mat-card class="dashboard-card">
        
      <table mat-table [dataSource]="totalAvoidableTable" matSort style="width: 100%;"
        class="mat-elevation-z8 total-avoid-table" [ng2-loading]="costsUpdating">
        <!-- Position Column -->
        <ng-container matColumnDef="Info">
          <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
          <td mat-cell *matCellDef="let element">
            <mat-icon style="margin-right:5px;" [matTooltip]="element.Info" matTooltipPosition="right">info</mat-icon>
          </td>
        </ng-container>
        <ng-container matColumnDef="Description">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Total Avoidable Costs {{ totalAvoidable | currency }} </th>
          <td mat-cell *matCellDef="let element"> {{element.Description}} </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="Amount" >
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Amount</th>
          <td mat-cell *matCellDef="let element"> {{element.Amount | currency}} </td>
        </ng-container>

        <!-- Percentage Column -->
        <ng-container matColumnDef="ChargeCount">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Count </th>
          <td mat-cell *matCellDef="let element"> {{element.ChargeCount}} </td>
        </ng-container>

        <!-- Report Link Column -->
        <ng-container matColumnDef="avoid-report">
          <th mat-header-cell *matHeaderCellDef> Report </th>
          <td mat-cell *matCellDef="let element"> <button mat-button style="width:100%;text-align: left;"
              (click)="goToReport(element.ReportId, element.Description)">
              <mat-icon>play_arrow</mat-icon>
            </button> </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      </mat-card>
    </div>
    <div fxFlex="50%" fxFlex.md="50%" fxFlex.xs="100%" fxFlex.sm="100%" >
      <mat-card class="dashboard-card" >
          <mat-card-title>Avoidable Cost by Week</mat-card-title>
          <mat-card-content>
              <div class="chart-wrapper" [ng2-loading]="costChartUpdate">
                <canvas baseChart [datasets]="costChartData" [labels]="costChartLabels"
                [options]="costChartOptions" [legend]="costChartLegend" [chartType]="costChartType"
                [colors]="costChartColors">
            </canvas>
              </div>
          </mat-card-content>
      </mat-card>

  </div>
</div>