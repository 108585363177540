import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Account, Airbill, Carrier, SearchApiRequest, SearchField, StandardApiRequest, TrackingDataResponse, UserInfo } from '../helpers/interfaces';
import { ApiService } from '../services/api.service.service';
import { SessionService } from '../services/session.service';
import { UserService } from '../services/user.service';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { MatSort } from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as fileSaver from 'file-saver';

@Component({
  selector: 'app-package-search',
  templateUrl: './package-search.component.html',
  styleUrls: ['./package-search.component.css']
})

export class PackageSearchComponent implements OnInit, AfterViewInit  {

  constructor(private apiService: ApiService, private notification: MatSnackBar, private sessionService: SessionService, private userService: UserService) { }

  userInfo!: UserInfo;
  formErrors: boolean = false;
  displayedColumns: string[] = ['airbill', 'AirbillNumber', 'tracking-detail', 'CarrierServiceName', 'TotalCharge', 'IncentiveAmount', 'AccountNumber', 'InvoiceNumber', 'ShipperCompany', 'ShipperName', 'ShipperAddress', 'ShipperCity', 'ShipperState', 'ShipperZip', 'ShipperCountry', 'RecipCompany', 'RecipName', 'RecipAddress', 'RecipCity', 'RecipState', 'RecipZip', 'RecipCountry', 'ReferenceNumber', 'PickupRecordNumber', 'PickupDate', 'SignedBy', 'DeliveryDate', 'Deadline', 'Weight', 'WeightUnits'];
  displayedTrackingColumns: string[] = ['airbill', 'tracking-number', 'service', 'acount', 'invoice'];
  currentAirbill: Airbill | undefined;

  paginator!: MatPaginator;
  @ViewChild('paginator') set MatPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }
  
  @ViewChild('trackingPaginator')
  trackingPaginator!: MatPaginator;

  @ViewChild('trackingGrid')
  htmlData!: ElementRef;

  @ViewChild(MatSort)
  sort!: MatSort;

  dateToday: Date = new Date();
  form: SearchApiRequest = {
    StartDate: new Date(new Date(Date.now() - 365 * 24 * 60 * 60 * 1000)),
    EndDate: new Date(this.dateToday),
    CarrierId: 0,
    AccountId: 0,
    ClientId: 0,
    SearchFieldId: 0,
    SearchValue: ''
  }

  Packages: MatTableDataSource<Airbill> = new MatTableDataSource();
  Tracking!: MatTableDataSource<TrackingDataResponse>;
  Carriers: Carrier[] = [{ CarrierId: 0, CarrierName: "All" }]
  Accounts: Account[] = [{ AccountId: 0, AccountNumber: "All" }]
  SearchFields: SearchField[] = []
  errorMessage: string = "";
  currentlySearching: boolean = false;
  showTracking: boolean = false;
  hasPackages: boolean = false;

  setDataSourceAttributes(): void {
    this.Packages.paginator = this.paginator;
    this.Packages.sort = this.sort;
  }

  ngAfterViewInit() {

  }

  ngOnInit(): void {
    this.userInfo = this.userService.userInfo;
    this.sessionService.searchForm.ClientId = this.userInfo.ClientId;
    this.form = this.sessionService.searchForm;

    this.SearchFields = this.sessionService.SearchFields;
    if (this.SearchFields.length === 0) {
      //get search fields from database
      this.apiService.makeStandardApiGet('values/GetSearchFields').subscribe(
        (data: any) => {
          if (data.StatusCode != 401) {
            const searchFields: SearchField[] = JSON.parse(data.Value);
            this.SearchFields = [];
            searchFields.forEach(element => {
              this.SearchFields.push(element);
            });
            this.sessionService.SearchFields = this.SearchFields;
          }
        }
      );
    }

    

    this.apiService.makeStandardApiCall('user/GetCarriersByClient', this.form).subscribe(
      (data: any) => {
        if (data.StatusCode != 401) {
          const carriers: Carrier[] = JSON.parse(data.Value);
          this.Carriers = [];
          this.Carriers.push({ CarrierName: 'All', CarrierId: 0 });
          carriers.forEach(element => {
            this.Carriers.push(element);
          });
        }
      }
    );
    this.updateAccountsList();
  }

  updateAccountsList(): void {
    this.apiService.makeStandardApiCall('user/GetAccountsByClientCarrier', this.form).subscribe(
      (data: any) => {
        if (data.StatusCode != 401) {
          const accounts: Account[] = JSON.parse(data.Value);
          this.Accounts = [];
          this.Accounts.push({ AccountNumber: 'All', AccountId: 0 });
          accounts.forEach(element => {
            this.Accounts.push({ AccountNumber: element.AccountNumber.trim().toUpperCase(), AccountId: element.AccountId });
          });
        }
      }
    );
  }

  searchPackages(): void {
    this.formErrors = false;
    this.errorMessage = '';
    if (this.form.SearchFieldId === 0) {
      this.formErrors = true;
      this.errorMessage += "Field to Search must be selected.\n";
    }
    if (this.form.SearchValue === undefined || this.form.SearchValue.length === 0) {
      this.formErrors = true;
      this.errorMessage += "Search Value must be entered.\n";
    }
    
    if (!this.formErrors) {
      this.currentlySearching = true;
      this.showTracking = false;
      this.hasPackages = false;
      this.apiService.makeStandardApiCall('values/SearchPackages', this.form).subscribe(
        (data: any) => {
          if (data.StatusCode != 401) {
            this.Packages = new MatTableDataSource<Airbill>(JSON.parse(data.Value));
            this.Packages.paginator = this.paginator;
            this.Packages.sort = this.sort;
            this.hasPackages = true;
            this.currentlySearching = false;
          }
        }
      );
    }
  }

  viewAirbill(airbill: Airbill): void {
    this.currentAirbill = airbill;
  }

  displayTracking(airbill: Airbill): void {
    const airbillRequest = {
      AirbillId: airbill
    }
    this.currentlySearching = true;
    this.apiService.makeStandardApiCall('values/GetTrackingData', airbillRequest).subscribe(
      (data: any) => {
        if (data.StatusCode != 401) {
          this.Tracking = new MatTableDataSource<TrackingDataResponse>(JSON.parse(data.Value));
          this.Tracking.paginator = this.trackingPaginator;
          this.currentlySearching = false;
          this.showTracking = true;
        }
      }
    );
  }

  hideTracking(): void {
    this.showTracking = false;
  }

  public openPDF():void {
    const DATA = document.getElementById('trackingGrid') as HTMLElement;
      
    html2canvas(DATA).then(canvas => {
        
        let fileWidth = 208;
        let fileHeight = canvas.height * fileWidth / canvas.width;
        
        const FILEURI = canvas.toDataURL('image/png')
        let PDF = new jsPDF('p', 'mm', 'a4');
        let position = 0;
        PDF.addImage(FILEURI, 'PNG', 0, position, fileWidth, fileHeight)
        
        PDF.save('TrackingDetail_' + this.currentAirbill?.AirbillNumber + '.pdf');
    });     
  }

  downloadSearch(): void {
    this.formErrors = false;
    this.errorMessage = '';
    if (this.form.SearchFieldId === 0) {
      this.formErrors = true;
      this.errorMessage += "Field to Search must be selected.\n";
    }
    if (this.form.SearchValue === undefined || this.form.SearchValue.length === 0) {
      this.formErrors = true;
      this.errorMessage += "Search Value must be entered.\n";
    }
    
    if (!this.formErrors) {
      this.currentlySearching = false;
      this.apiService.downloadSearchResultsFile(this.form).subscribe((response: any) => { //when you use stricter type checking
        // const blob = new Blob([response], { type: 'application/octet-stream' });
        // const url= window.URL.createObjectURL(blob);
        // window.open(url);
        let blob: any = new Blob([response], { type: 'application/octet-stream' });
        const url = window.URL.createObjectURL(blob);
        fileSaver.saveAs(blob, 'SearchResults.xlsx');
        this.currentlySearching = false;
      }), (error: any) => this.currentlySearching = false, //when you use stricter type checking
        () => this.notification.open('File downloaded successfully');
    }
  }

  downloadTracking(airbillNumber: string | undefined): void {
    this.currentlySearching = false;
    this.apiService.downloadTrackingResultsFile(this.Tracking.data).subscribe((response: any) => { //when you use stricter type checking
      // const blob = new Blob([response], { type: 'application/octet-stream' });
      // const url= window.URL.createObjectURL(blob);
      // window.open(url);
      let blob: any = new Blob([response], { type: 'application/octet-stream' });
      const url = window.URL.createObjectURL(blob);
      fileSaver.saveAs(blob, airbillNumber + '_TrackingData.xlsx');
      this.currentlySearching = false;
    }), (error: any) => this.currentlySearching = false, //when you use stricter type checking
      () => this.notification.open('File downloaded successfully');
}
}
