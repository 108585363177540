import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ChartOptions } from 'chart.js';
import { appConstants } from '../helpers/constants';
import { Account, Carrier, PerformanceByService, PerformanceStats, RefundTotalByDisputeType, StandardApiRequest, TrackingException, UserInfo } from '../helpers/interfaces';
import { ApiService } from '../services/api.service.service';
import { SessionService } from '../services/session.service';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-performance',
  templateUrl: './performance.component.html',
  styleUrls: ['./performance.component.css']
})
export class PerformanceComponent implements OnInit {

  constructor(private apiService: ApiService, private router: Router, private sessionService: SessionService, private userService: UserService) { }

  userInfo!: UserInfo;
  dateToday: Date = new Date();
  form: StandardApiRequest = {
    StartDate: new Date(this.dateToday.getFullYear(), 0, 1),
    EndDate: new Date(this.dateToday),
    CarrierId: 0,
    AccountId: 0,
    ClientId: 0,
    IsPageLoad: false
  }

  defaultScale = appConstants.fun.getChartScale('Month', 'Amount in Dollars');

  performanceStats: PerformanceStats = {
    NonInvoicedRefunds: 0,
    OntimePercentage: 0,
    RawLate: 0,
    TotalPackage: 0,
    TotalRefunds: 0
  };

  // [Total NET-CHARGE]
  totalRefundsByMonthData: Array<any> = [{ data: [] }];
  totalRefundsByMonthLabels: Array<any> = [];
  totalRefundsByMonthOptions: any = {
    responsive: true,
    scales: this.defaultScale,
    isLoading: true,
    tooltips: {
      enabled: true,
      mode: 'single',
      callbacks: {
        label: function (tooltipItems: { yLabel: string; }, data: any) {
          return ' $' + tooltipItems.yLabel;
        }
      }
    }
  };
  totalRefundsByMonthLegend: boolean = false;
  totalRefundsByMonthType: any = appConstants.CHARTS.TYPES.LINE_CHART;

  public totalRefundsByMonthColors: Array<any> = [{}];
  totalRefundsLoading = true;

  // Refund total chart variables
  refundTotalChartLabels: Array<any> = [];
  refundTotalChartData: number[] = [];
  refundTotalChartType: any = appConstants.CHARTS.TYPES.PIE_CHART;
  refundTotalChartOptions: ChartOptions = {
    responsive: true,

  };
  refundTotalChartColors: Array<any> = [ // TODO get colors from common area
    { backgroundColor: ['rgb(255, 161, 181)', 'rgb(134, 199, 243)', 'rgb(255, 226, 154)', 'rgb(147, 217, 217)', 'rgb(147, 145, 118)', 'rgb(107, 51, 34)', 'rgb(145, 150, 43)', 'rgb(27, 67, 71)', 'rgb(38, 38, 37)', 'rgb(94, 201, 174)'] }
  ];
  refundTotalLoading: boolean = true;

  servicePerformance: any = {
    option: {
      responsive: true,
      isLoading: false,
      scales: {
        xAxes: [{
          ticks: {
            beginAtZero: true
          },
          scaleLabel: {
            display: true,
            labelString: 'Percentage On-Time'
          }
        }]
      },
      tooltips: {
        enabled: true,
        mode: 'single',
        callbacks: {
          label: function (tooltipItems: { yLabel: any, xLabel: any; }, data: any) {
            return tooltipItems.xLabel + ' %';
          }
        }
      },

    },
    label: null,
    type: appConstants.CHARTS.TYPES.HORIZONTAL_BAR,

    legend: false,
    data: [{
      data: []
    }],


  };

  servicePerformanceChartColors: Array<any> = [{}];
  servicePerformanceLoading: boolean = true;

  trackingException: any = {
    option: {
      scaleShowVerticalLines: false,
      responsive: true,
      isLoading: true,
      scales: {
        xAxes: [{
          ticks: {
            beginAtZero: true
          },
          scaleLabel: {
            display: true,
            labelString: 'Total Shipments'
          }
        }]
      }
    },
    label: null,
    type: appConstants.CHARTS.TYPES.HORIZONTAL_BAR,
    legend: false,
    data: [{ data: [] }]
  };

  public trackingExceptionChartColors: Array<any> = [{}];
  trackingExceptionsLoading: boolean = true;
  statsLoading: boolean = true;

  Carriers: Carrier[] = [{ CarrierId: 0, CarrierName: "All" }]
  Accounts: Account[] = [{ AccountId: 0, AccountNumber: "All" }]

  ngOnInit(): void {
    this.userInfo = this.userService.userInfo;
    this.sessionService.performanceForm.ClientId = this.userInfo.ClientId;
    this.form = this.sessionService.performanceForm;
    
    this.apiService.makeStandardApiCall('user/GetCarriersByClient', this.form).subscribe(
      (data: any) => {
        if (data.StatusCode != 401) {
          const carriers: Carrier[] = JSON.parse(data.Value);
          this.Carriers = [];
          this.Carriers.push({ CarrierName: 'All', CarrierId: 0 });
          carriers.forEach(element => {
            this.Carriers.push(element);
          });
        }
      }
    );
    this.updateDashboardComponents();
    this.updateAccountsList();
  }

  updateAccountsList(): void {
    this.apiService.makeStandardApiCall('user/GetAccountsByClientCarrier', this.form).subscribe(
      (data: any) => {
        if (data.StatusCode != 401) {
          const accounts: Account[] = JSON.parse(data.Value);
          this.Accounts = [];
          this.Accounts.push({ AccountNumber: 'All', AccountId: 0 });
          accounts.forEach(element => {
            this.Accounts.push({ AccountNumber: element.AccountNumber.trim().toUpperCase(), AccountId: element.AccountId });
          });
        }
      }
    );
  }

  updateDashboardComponents(): void {
    this.sessionService.performanceForm = this.form;

    this.statsLoading = true;
    this.apiService.makeStandardApiCall('values/GetPerformanceStats', this.form).subscribe(
      (data: any) => {
        this.performanceStats = JSON.parse(data.Value);
        this.statsLoading = false;
      }
    );

    this.totalRefundsLoading = true;
    this.apiService.makeStandardApiCall('values/GetRefundByMonth', this.form).subscribe(
      (data: any) => {
        const chartData = appConstants.fun.parseChartDataFromApi(JSON.parse(data.Value), 'TotalRefunds');

        // Set values to chart
        this.totalRefundsByMonthLabels = chartData.labels;
        this.totalRefundsByMonthData[0].data = chartData.values;
        this.totalRefundsByMonthOptions.isLoading = false;
        this.totalRefundsByMonthOptions.tooltips.callbacks;
        this.totalRefundsByMonthColors = [
          {
            backgroundColor: 'rgba(255,255,255,0)',
            borderColor: 'rgba(102,163,255,1)', // Data drive this
            pointBackgroundColor: 'rgba(102,163,255,1)',
            pointBorderColor: 'rgba(102,163,255,0)',
          }
        ];
        this.totalRefundsLoading = false;
      }
    );

    this.refundTotalLoading = true;
    this.apiService.makeStandardApiCall('values/GetRefundTotal', this.form).subscribe(
      (data: any) => {
        // Temp variables
        const labelsTemp: Array<string> = [];
        const valuesTemp: Array<number> = [];
        const returnData: Array<RefundTotalByDisputeType> = JSON.parse(data.Value);
        // Populate
        returnData.forEach(element => {
          if ((this.form.CarrierId === appConstants.Carriers.FEDEX) && (element.CarrierId !== appConstants.Carriers.FEDEX)) {
            return;
          }
          if ((this.form.CarrierId === appConstants.Carriers.UPS) && (element.CarrierId !== appConstants.Carriers.UPS)) {
            return;
          }
          labelsTemp.push(element.DisputeType);
          valuesTemp.push(element.RefundTotal);
        });

        // Set values to chart
        this.refundTotalChartLabels = labelsTemp;
        this.refundTotalChartData = valuesTemp;

        this.refundTotalLoading = false;
      }
    );

    this.servicePerformanceLoading = true;
    this.apiService.makeStandardApiCall('values/GetPerformanceByService', this.form).subscribe(
      (data: any) => {
        const labelsTemp: Array<string> = [];
        const valuesTemp: Array<number> = [];
        const returnData: Array<PerformanceByService> = JSON.parse(data.Value);
        // Populate
        returnData.forEach(element => {
          labelsTemp.push(element.ServiceGroupName);
					valuesTemp.push(element.OntimePercentage);
        });

				// Set values to chart
				this.servicePerformance.label = labelsTemp;
				this.servicePerformance.data[0].data = valuesTemp;
        this.servicePerformance.option.tooltips.callbacks;
        this.servicePerformanceChartColors = [
					{
						backgroundColor: 'rgb(102,163,255,1)'
					}
				];
        this.servicePerformanceLoading = false
      }
    );

    this.trackingExceptionsLoading = true;
    this.apiService.makeStandardApiCall('values/GetTrackingException', this.form).subscribe(
      (data: any) => {
        const labelsTemp: Array<string> = [];
        const valuesTemp: Array<number> = [];
        const returnData: Array<TrackingException> = JSON.parse(data.Value);
        // Populate
        returnData.forEach(element => {
          labelsTemp.push(element.ScanCodeReason.trim());
					valuesTemp.push(element.ExceptionCount);
        });


        // Set values to chart
        this.trackingException.label = labelsTemp;
        this.trackingException.data[0].data = valuesTemp;
        this.trackingException.isLoading = false;
        this.trackingExceptionChartColors = [
          {
            backgroundColor: 'rgb(102,163,255,1)',
          }
         ];
        this.trackingExceptionsLoading = false;
      }
    );
  }

  goToLink(link: string): void {
    const navigationDetails: string[] = [link];
    this.router.navigate(navigationDetails);
  }
}