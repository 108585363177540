<div class="row">
    <mat-card style="margin: 20px;">
        <mat-card-title>FILTERS</mat-card-title>
        <mat-form-field style="margin: 5px;" appearance="fill">
            <mat-label>Start Date</mat-label>
            <input matInput [matDatepicker]="startdate" [(ngModel)]="form.StartDate">
            <mat-datepicker-toggle matSuffix [for]="startdate"></mat-datepicker-toggle>
            <mat-datepicker #startdate></mat-datepicker>
        </mat-form-field>
        <mat-form-field style="margin: 5px;" appearance="fill">
            <mat-label>End Date</mat-label>
            <input matInput [matDatepicker]="enddate" [(ngModel)]="form.EndDate">
            <mat-datepicker-toggle matSuffix [for]="enddate"></mat-datepicker-toggle>
            <mat-datepicker #enddate></mat-datepicker>
        </mat-form-field>
        <mat-form-field style="margin: 5px;" appearance="fill">
            <mat-label>Carrier</mat-label>
            <mat-select [(ngModel)]="form.CarrierId" name="carrier" (change)="updateAccountsList()">
                <mat-option *ngFor="let car of Carriers" [value]="car.CarrierId">
                    {{car.CarrierName}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field style="margin: 5px;" appearance="fill">
            <mat-label>Account</mat-label>
            <mat-select [(ngModel)]="form.AccountId" name="account">
                <mat-option *ngFor="let acc of Accounts" [value]="acc.AccountId">
                    {{acc.AccountNumber}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <div style="display: inline-block;position: relative;text-align:left;margin-left:20px;">
            <button mat-raised-button (click)="updateDashboardComponents();">
                Update
            </button>
            <button style="margin-left: 15px;" mat-raised-button (click)="downloadSearch();">
                Download
            </button>
        </div>
    </mat-card>
    <div fxLayout="row wrap">
        <div fxFlex="50%" fxFlex.md="100%" fxFlex.xs="100%" fxFlex.sm="100%">
            <mat-card class="dashboard-card">
                <mat-card-title>ON TIME PERFORMANCE BY SERVICE</mat-card-title>
                <mat-card-content>
                    <div class="chart-wrapper" [ng2-loading]="servicePerformanceLoading">
                        <canvas baseChart [datasets]="servicePerformance.data"
                        [labels]="servicePerformance.label"
                        [options]="servicePerformance.option"
                        [legend]="servicePerformance.legend"
                        [colors]="servicePerformanceChartColors"
                        [chartType]="servicePerformance.type">
                        </canvas>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
        <div fxFlex="50%" fxFlex.md="100%" fxFlex.xs="100%" fxFlex.sm="100%">
            <mat-card class="dashboard-card">
                <table mat-table [dataSource]="dataTable" matSort style="width: 100%;"
                class="mat-elevation-z8 net-charge-table" [ng2-loading]="servicePerformanceLoading">
                
                <ng-container matColumnDef="month">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Service Group Name </th>
                  <td mat-cell *matCellDef="let element"> {{element.ServiceGroupName }} </td>
                </ng-container>
        
                <ng-container matColumnDef="year">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> 	Percentage On Time </th>
                    <td mat-cell *matCellDef="let element"> {{element.OntimePercentage }}% </td>
                  </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>
               
            </mat-card>
        </div>
    </div>
   
</div>