import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ChartOptions, ChartType } from 'chart.js';
import * as fileSaver from 'file-saver';
import { Label, MultiDataSet } from 'ng2-charts';
import { SurchargeByType, StandardApiRequest, Carrier, Account, PackageCountByZone, UserInfo } from '../helpers/interfaces';
import { ApiService } from '../services/api.service.service';
import { SessionService } from '../services/session.service';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-packages-weight-chart',
  templateUrl: './packages-weight-chart.component.html',
  styleUrls: ['./packages-weight-chart.component.css']
})
export class PackagesWeightChartComponent implements OnInit {

  displayedColumns: string[] = ['service-zone', 'service-group', 'package-count', 'net-charge'];
  packageZoneTable: MatTableDataSource<PackageCountByZone> = new MatTableDataSource();
 
  layeredPackagesByZonebarChartOptions: any = {
    scaleShowVerticalLines: false,
    responsive: true,
    loading: true,
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: true
        },
        scaleLabel: {
          display: true,
          labelString: 'Package Count'
        }
      }],
      xAxes: [{
        ticks: {
          
        },
        scaleLabel: {
          display: true,
          labelString: 'Zone'
        }
      }]
    }
  };
  layeredPackagesByZonebarChartLabels: string[] = [];
  layeredPackagesByZonebarChartType: ChartType = 'bar';
  layeredPackagesByZonebarChartLegend: boolean = true;

  layeredPackagesByZonebarChartData: any[] = [];
  layeredPackagesByZonebarChartColors: Array<any> = [ // TODO get colors from common area
    { backgroundColor: 'rgb(255, 161, 181)' },
    { backgroundColor: 'rgb(134, 199, 243)' },
    { backgroundColor: 'rgb(255, 226, 154)' },
    { backgroundColor: 'rgb(147, 217, 217)' },
    { backgroundColor: 'rgb(147, 145, 118)' },
    { backgroundColor: 'rgb(107, 51, 34)' },
    { backgroundColor: 'rgb(145, 150, 43)' },
    { backgroundColor: 'rgb(27, 67, 71)' },
    { backgroundColor: 'rgb(38, 38, 37)' },
    { backgroundColor: 'rgb(94, 201, 174)' }
  ];

  layeredPackageLoading: boolean = true;
  packageCountByZone: PackageCountByZone[] = []
  
  basicOptions: any;
  constructor(private apiService: ApiService, private notification: MatSnackBar, private router: Router, private sessionService: SessionService, private userService: UserService) { }

  dateToday: Date = new Date();
  form: StandardApiRequest = {
    StartDate: new Date(new Date(Date.now() - 14 * 24 * 60 * 60 * 1000)),
    EndDate: new Date(this.dateToday),
    CarrierId: 0,
    AccountId: 0,
    ClientId: 0,
    IsPageLoad: false
  }

  userInfo!: UserInfo;
  apiData: StandardApiRequest = {
    StartDate: new Date(new Date(Date.now() - 14 * 24 * 60 * 60 * 1000)),
    EndDate: new Date(this.dateToday),
    CarrierId: 0,
    AccountId: 0,
    ClientId: 0,
    IsPageLoad: false
  };

  Carriers: Carrier[] = [{ CarrierId: 0, CarrierName: "All" }]
  Accounts: Account[] = [{ AccountId: 0, AccountNumber: "All" }]

  ngAfterViewInit() {


  }

  ngOnInit(): void {
    this.userInfo = this.userService.userInfo;
    this.sessionService.dashboardStatForm.ClientId = this.userInfo.ClientId;
    this.form = this.sessionService.dashboardStatForm;

    this.apiData.ClientId = this.userInfo.ClientId;
    this.apiService.makeStandardApiCall('user/GetCarriersByClient', this.apiData).subscribe(
      (data: any) => {
        if (data.StatusCode != 401) {
          const carriers: Carrier[] = JSON.parse(data.Value);
          this.Carriers = [];
          this.Carriers.push({ CarrierName: 'All', CarrierId: 0 });
          carriers.forEach(element => {
            this.Carriers.push(element);
          });
        }
      }
    );
    this.updateDashboardComponents();
    this.updateAccountsList();
  }

  onSubmit(): void {
    this.updateDashboardComponents();
  }

  updateAccountsList(): void {
    this.apiService.makeStandardApiCall('user/GetAccountsByClientCarrier', this.form).subscribe(
      (data: any) => {
        if (data.StatusCode != 401) {
          const accounts: Account[] = JSON.parse(data.Value);
          this.Accounts = [];
          this.Accounts.push({ AccountNumber: 'All', AccountId: 0 });
          accounts.forEach(element => {
            this.Accounts.push({ AccountNumber: element.AccountNumber.trim().toUpperCase(), AccountId: element.AccountId });
          });
        }
      }
    );
  }

  updateDashboardComponents(): void {
    this.apiData = {
      AccountId: this.form.AccountId,
      CarrierId: this.form.CarrierId,
      ClientId: this.userInfo.ClientId,
      StartDate: this.form.StartDate,
      EndDate: this.form.EndDate,
      IsPageLoad: false
    };

    this.sessionService.dashboardStatForm = this.form;

    this.layeredPackageLoading = true;
    this.apiService.makeStandardApiCall('values/GetLayeredPackagesByZone', this.apiData).subscribe(
      (data: any) => {
        if (data.StatusCode != 401) {
          this.packageCountByZone = JSON.parse(data.Value);
          this.packageZoneTable = new MatTableDataSource<PackageCountByZone>(JSON.parse(data.Value))
          const paresdArr: { data: number[], label: string }[] = [];
          const chartLabel: Label = [];
          this.packageCountByZone.forEach(
            (result) => {
              // Flag for element found in object
              let found = false;

              // Iterate parsed data and append data accordingly
              paresdArr.forEach((item) => {
                if (item.label === result.ServiceGroupName) {
                  found = true;
                }
              });

              // If not found create new entry
              if (!found) {
                paresdArr.push({ data: [], label: result.ServiceGroupName });
              }

            }
          );

          this.packageCountByZone.forEach(
            (result) => {
              if ((chartLabel.indexOf(result.ZoneSN.toString()) === -1)) {
                chartLabel.push(result.ZoneSN.toString());
              }
            }
          );

          chartLabel.forEach((chartItem) => {
            paresdArr.forEach((groupItem) => {
              let found = false;
              let value = 0;
              this.packageCountByZone.forEach(
                (result) => {
                  // Flag for element found in object
                  let found = false;

                  // Iterate parsed data and append data accordingly

                  if (groupItem.label === result.ServiceGroupName && chartItem === result.ZoneSN.toString()) {
                    value = result.PackageCount;
                    found = true;
                  }


                }

              );

              groupItem.data.push(value);

            });
          });

          // Add chart labels

          // If no data from api set default data to display empty chart
          if (paresdArr.length === 0) {
            paresdArr.push({ data: [], label: 'No data' });
          }

          //// Construct labels
          //for (let i = 1; i <= paresdArr[0].data.length; i++) {
          //	chartLabel.push(i.toString());
          //}

          this.layeredPackagesByZonebarChartLabels = chartLabel;
          this.layeredPackagesByZonebarChartData = paresdArr;
          this.layeredPackagesByZonebarChartOptions.loading = false;
        }
        this.layeredPackageLoading = false;
      }
    );

  }

  downloadSearch(): void {
    this.apiService.downloadLayeredPackageByZone(this.apiData).subscribe((response: any) => { //when you use stricter type checking
      // const blob = new Blob([response], { type: 'application/octet-stream' });
      // const url= window.URL.createObjectURL(blob);
      // window.open(url);
      let blob: any = new Blob([response], { type: 'application/octet-stream' });
      const url = window.URL.createObjectURL(blob);
      fileSaver.saveAs(blob, 'PackagesByZone.xlsx');
    }), (error: any) => 
      () => this.notification.open('File downloaded successfully');
  }
}
