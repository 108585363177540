import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserInfo } from '../helpers/interfaces';
import { ApiService } from '../services/api.service.service';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-add-client',
  templateUrl: './add-client.component.html',
  styleUrls: ['./add-client.component.css']
})
export class AddClientComponent implements OnInit {
  form: UserInfo = {
    Address: '',
    City: '',
    ClientId: 0,
    CompanyName: '',
    Email: '',
    FirstName: '',
    LastName: '',
    Occupation: '',
    Password: '',
    ResetToken: '',
    State: '',
    TokenAdded: '',
    UserName: '',
    Zip: '',
    UserType: 'USER'
  };

  constructor(private userService: UserService, private apiService: ApiService, private notification: MatSnackBar ) { }

  ngOnInit(): void {
   
  }

  onSubmit(): void {
    this.apiService.makeStandardApiCall('user/AddUserProfile', this.form).subscribe(
      data => {
          if (data.StatusCode == null || data.StatusCode == undefined ){
            this.notification.open('Error Adding User!', 'Dismiss', {
              duration: 3000
            });
          } else {
            this.notification.open('User Added!', 'Dismiss', {
              duration: 3000
            });
            this.form = {
              Address: '',
              City: '',
              ClientId: 0,
              CompanyName: '',
              Email: '',
              FirstName: '',
              LastName: '',
              Occupation: '',
              Password: '',
              ResetToken: '',
              State: '',
              TokenAdded: '',
              UserName: '',
              Zip: '',
              UserType: 'USER'
            };
        }
        
      },
      
    );;
  }
}
