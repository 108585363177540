import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ChartDataSets, ChartType } from 'chart.js';
import * as fileSaver from 'file-saver';
import { Label } from 'ng2-charts';
import { PackageCountByZone, StandardApiRequest, Carrier, Account, UserInfo, PackageCountByWeight } from '../helpers/interfaces';
import { ApiService } from '../services/api.service.service';
import { SessionService } from '../services/session.service';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-count-weight-chart',
  templateUrl: './count-weight-chart.component.html',
  styleUrls: ['./count-weight-chart.component.css']
})
export class CountWeightChartComponent implements OnInit {

  displayedColumns: string[] = ['service-zone', 'service-group', 'package-count'];
  packageByWeightTable: MatTableDataSource<PackageCountByWeight> = new MatTableDataSource();
 
  weightChartOptions: any = {
    scaleShowVerticalLines: false,
    responsive: true,
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: true
        },
        scaleLabel: {
          display: true,
          labelString: 'Package Count'
        }
      }],
      xAxes: [{
        ticks: {
          
        },
        scaleLabel: {
          display: true,
          labelString: 'Weight'
        }
      }]
    }
  };
  weightChartLabels: string[] = [];
  weightChartType: ChartType = 'bar';
  weightChartLegend: boolean = true;
  weightChartData: ChartDataSets[] = [];
  weightChartLoading: boolean = true;

  packageCountLoading: boolean = true;
  packageCountByWeight: PackageCountByWeight[] = []
  
  basicOptions: any;
  constructor(private apiService: ApiService, private notification: MatSnackBar, private router: Router, private sessionService: SessionService, private userService: UserService) { }

  dateToday: Date = new Date();
  form: StandardApiRequest = {
    StartDate: new Date(new Date(Date.now() - 14 * 24 * 60 * 60 * 1000)),
    EndDate: new Date(this.dateToday),
    CarrierId: 0,
    AccountId: 0,
    ClientId: 0,
    IsPageLoad: false
  }

  userInfo!: UserInfo;
  apiData: StandardApiRequest = {
    StartDate: new Date(new Date(Date.now() - 14 * 24 * 60 * 60 * 1000)),
    EndDate: new Date(this.dateToday),
    CarrierId: 0,
    AccountId: 0,
    ClientId: 0,
    IsPageLoad: false
  };

  Carriers: Carrier[] = [{ CarrierId: 0, CarrierName: "All" }]
  Accounts: Account[] = [{ AccountId: 0, AccountNumber: "All" }]

  ngAfterViewInit() {


  }

  ngOnInit(): void {
    this.userInfo = this.userService.userInfo;
    this.sessionService.dashboardStatForm.ClientId = this.userInfo.ClientId;
    this.form = this.sessionService.dashboardStatForm;

    this.apiData.ClientId = this.userInfo.ClientId;
    this.apiService.makeStandardApiCall('user/GetCarriersByClient', this.apiData).subscribe(
      (data: any) => {
        if (data.StatusCode != 401) {
          const carriers: Carrier[] = JSON.parse(data.Value);
          this.Carriers = [];
          this.Carriers.push({ CarrierName: 'All', CarrierId: 0 });
          carriers.forEach(element => {
            this.Carriers.push(element);
          });
        }
      }
    );
    this.updateDashboardComponents();
    this.updateAccountsList();
  }

  onSubmit(): void {
    this.updateDashboardComponents();
  }

  updateAccountsList(): void {
    this.apiService.makeStandardApiCall('user/GetAccountsByClientCarrier', this.form).subscribe(
      (data: any) => {
        if (data.StatusCode != 401) {
          const accounts: Account[] = JSON.parse(data.Value);
          this.Accounts = [];
          this.Accounts.push({ AccountNumber: 'All', AccountId: 0 });
          accounts.forEach(element => {
            this.Accounts.push({ AccountNumber: element.AccountNumber.trim().toUpperCase(), AccountId: element.AccountId });
          });
        }
      }
    );
  }

  updateDashboardComponents(): void {
    this.apiData = {
      AccountId: this.form.AccountId,
      CarrierId: this.form.CarrierId,
      ClientId: this.userInfo.ClientId,
      StartDate: this.form.StartDate,
      EndDate: this.form.EndDate,
      IsPageLoad: false
    };

    this.sessionService.dashboardStatForm = this.form;

    this.packageCountLoading = true;
    this.apiService.makeStandardApiCall('values/GetPackageCountByWeight', this.apiData).subscribe(
      (data: any) => {
        if (data.StatusCode != 401) {
          this.packageCountByWeight = JSON.parse(data.Value);
          this.packageByWeightTable = new MatTableDataSource<PackageCountByWeight>(JSON.parse(data.Value))
          
          const labels: string[] = [];
          const fedexData: any = [];
          const upsData: any = [];

          // Temporary hold data
          this.packageCountByWeight.forEach(item => {
            if ((this.form.CarrierId === 2) && (item.CarrierId !== 2)) {
              return;
            }
            if ((this.form.CarrierId === 1) && (item.CarrierId !== 1)) {
              return;
            }

            if (labels.indexOf(item.WeightBucketName) === -1) {

              labels.push(item.WeightBucketName);
            }

            if (item.CarrierId === 1) {
              fedexData[item.WeightBucketName] = item.PackageCount;
            }

            if (item.CarrierId === 2) {
              upsData[item.WeightBucketName] = item.PackageCount;
            }
          });

          const fedexChartData: any = {
            data: [],
            label: ['FEDEX'],
          };

          const upsChartData: any = {
            data: [],
            label: ['UPS'],
          };

          // Organise data for chart
          labels.forEach((item) => {
            if (fedexData[item]) {
              fedexChartData.data.push(fedexData[item]);
            } else {
              fedexChartData.data.push(0);
            }

            if (upsData[item]) {
              upsChartData.data.push(upsData[item]);
            } else {
              upsChartData.data.push(0);
            }
          });

          // Finally set chart data
          this.weightChartLabels = labels;

          // Empty data before pushing
          this.weightChartData = [];

          // Add labels according to labels
          switch (this.form.CarrierId) {
            case 0:
              this.weightChartData.push(fedexChartData);
              this.weightChartData.push(upsChartData);
              break;
            case 1:
              this.weightChartData.push(fedexChartData);
              break;
            case 2:
              this.weightChartData.push(upsChartData);
              break;
          }
          this.packageCountLoading = false;
        }
      }
    );

  }

  downloadSearch(): void {
    this.apiService.downloadPackageCountByWeight(this.apiData).subscribe((response: any) => { //when you use stricter type checking
      // const blob = new Blob([response], { type: 'application/octet-stream' });
      // const url= window.URL.createObjectURL(blob);
      // window.open(url);
      let blob: any = new Blob([response], { type: 'application/octet-stream' });
      const url = window.URL.createObjectURL(blob);
      fileSaver.saveAs(blob, 'PackagesByWeight.xlsx');
    }), (error: any) => 
      () => this.notification.open('File downloaded successfully');
  }
}
