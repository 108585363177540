import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import * as fileSaver from 'file-saver';

import { CarrierInvoice, ClientInvoice, ClientInvoiceRequest, DownloadClientInvoiceRequest, UserInfo } from '../helpers/interfaces';
import { ApiService } from '../services/api.service.service';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-carrier-invoices',
  templateUrl: './carrier-invoices.component.html',
  styleUrls: ['./carrier-invoices.component.css']
})
export class CarrierInvoicesComponent implements OnInit {
  constructor(private apiService: ApiService, private userService: UserService) { }

  displayedColumns: string[] = ['AccountNumber', 'InvoiceNumber', 'InvoiceDate', 'InvoiceAmount', 'invoice-action'];

  @ViewChild(MatSort)
  sort!: MatSort;
  
  userInfo!: UserInfo;
  clientInvoices!: MatTableDataSource<CarrierInvoice>;
  clientInvoiceRequest!: ClientInvoiceRequest;
  fileDownloading: boolean = false;

  @ViewChild('paginator')
  paginator!: MatPaginator;
  
  ngOnInit(): void {
    this.userInfo = this.userService.userInfo;

    this.clientInvoiceRequest = {
      ClientId: this.userInfo.ClientId,
      PageIndex: 1,
      PageSize: 10000,
      RepId: 0,
      SortColumn: 'InvoiceID',
      SortOrder: 'DESC'
    }

    this.apiService.makeStandardApiCall('values/GetCarrierInvoices', this.clientInvoiceRequest).subscribe(
      (data: any) => {
        if (data.StatusCode != 401) {
          this.clientInvoices = new MatTableDataSource<CarrierInvoice>(JSON.parse(data.Value));
          this.clientInvoices.paginator = this.paginator;
          this.clientInvoices.sort = this.sort;
        }
      }
    );
  }

  downloadInvoice(InvoiceNumber: number, FileName: string): void {
    this.fileDownloading = true;
    const downloadRequest: DownloadClientInvoiceRequest = {
      ClientInvoiceID: InvoiceNumber
    }
    this.apiService.downloadCarrierInvoiceFile(downloadRequest).subscribe((response: any) => { //when you use stricter type checking
      // const blob = new Blob([response], { type: 'application/octet-stream' });
      // const url= window.URL.createObjectURL(blob);
      // window.open(url);
      let blob: any = new Blob([response], { type: 'application/octet-stream' });
      const url = window.URL.createObjectURL(blob);
      let reportName = 'CarrierInvoice';
      fileSaver.saveAs(blob, FileName);
      this.fileDownloading = false;
    }), (error: any) => this.fileDownloading = false, console.log('Error downloading the file'), //when you use stricter type checking
      () => console.info('File downloaded successfully');
  }
}
