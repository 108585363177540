import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserInfo } from '../helpers/interfaces';
import { ApiService } from './api.service.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

const USER_KEY = 'auth-user';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(private http: HttpClient, private apiService: ApiService, private notification: MatSnackBar, private router: Router) { 
    const user = window.sessionStorage.getItem(USER_KEY);
    if (user) {
      this.userInfo = JSON.parse(user);
    }
  }

  public userInfo!: UserInfo;

  getUserInfo(username: string): void {
    this.apiService.makeUserCase(username).subscribe(
      (data: any) => {
        if (data.StatusCode != 401) {
          this.userInfo = JSON.parse(data.Value);
          this.saveUser(this.userInfo);
          this.notification.open('Login Successful!!');
          this.router.navigate(['/dashboard']);
        }
      }
    );
  }

  
  public saveUser(user: UserInfo): void {
    window.sessionStorage.removeItem(USER_KEY);
    window.sessionStorage.setItem(USER_KEY, JSON.stringify(user));
  }

  public getUser(): any {
    
  }
}