<div class="row" [ng2-loading]="fileDownloading">
<mat-card style="margin: 20px;">
    <mat-card-title>FILTERS</mat-card-title>
    <mat-form-field style="margin: 5px;" appearance="fill">
        <mat-label>Start Date</mat-label>
        <input matInput [matDatepicker]="startdate" [(ngModel)]="form.StartDate">
        <mat-datepicker-toggle matSuffix [for]="startdate"></mat-datepicker-toggle>
        <mat-datepicker #startdate></mat-datepicker>
    </mat-form-field>
    <mat-form-field style="margin: 5px;" appearance="fill">
        <mat-label>End Date</mat-label>
        <input matInput [matDatepicker]="enddate" [(ngModel)]="form.EndDate">
        <mat-datepicker-toggle matSuffix [for]="enddate"></mat-datepicker-toggle>
        <mat-datepicker #enddate></mat-datepicker>
    </mat-form-field>
    <mat-form-field style="margin: 5px;" appearance="fill">
        <mat-label>Carrier</mat-label>
        <mat-select [(ngModel)]="form.CarrierId" name="carrier" (change)="updateAccountsList()">
            <mat-option *ngFor="let car of Carriers" [value]="car.CarrierId">
                {{car.CarrierName}}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field style="margin: 5px;" appearance="fill">
        <mat-label>Account</mat-label>
        <mat-select [(ngModel)]="form.AccountId" name="account">
            <mat-option *ngFor="let acc of Accounts" [value]="acc.AccountId">
                {{acc.AccountNumber}}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field required style="margin: 5px;min-width: 350px;" appearance="fill">
        <mat-label>Report</mat-label>
        <mat-select [(ngModel)]="form.ReportId" name="report">
            <mat-option *ngFor="let rep of Reports" [value]="rep.ReportId">
                {{rep.ReportName}}
            </mat-option>
        </mat-select>
    </mat-form-field>
    
    <div style="display: inline-block;position: relative;text-align:left;margin-left:20px;">
        <button mat-raised-button (click)="downloadReport();">
            Download
        </button>
    </div>
    <div class="row">
        <mat-error
          class="alert alert-danger"
          role="alert"
          *ngIf="formErrors === true"
          style="white-space: pre-line"
        >
          {{ errorMessage }}
        </mat-error>
    </div>
</mat-card>
</div>