<div class="row" [ng2-loading]="currentlySearching">
    <mat-card style="margin: 20px;">
        <mat-card-title>FILTERS</mat-card-title>
        <div class="row">
            <mat-form-field style="margin: 5px;" appearance="fill">
                <mat-label>Start Date</mat-label>
                <input matInput [matDatepicker]="startdate" [(ngModel)]="form.StartDate">
                <mat-datepicker-toggle matSuffix [for]="startdate"></mat-datepicker-toggle>
                <mat-datepicker #startdate></mat-datepicker>
            </mat-form-field>
            <mat-form-field style="margin: 5px;" appearance="fill">
                <mat-label>End Date</mat-label>
                <input matInput [matDatepicker]="enddate" [(ngModel)]="form.EndDate">
                <mat-datepicker-toggle matSuffix [for]="enddate"></mat-datepicker-toggle>
                <mat-datepicker #enddate></mat-datepicker>
            </mat-form-field>
            <mat-form-field style="margin: 5px;" appearance="fill">
                <mat-label>Carrier</mat-label>
                <mat-select [(ngModel)]="form.CarrierId" name="carrier" (change)="updateAccountsList()">
                    <mat-option *ngFor="let car of Carriers" [value]="car.CarrierId">
                        {{car.CarrierName}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field style="margin: 5px;" appearance="fill">
                <mat-label>Account</mat-label>
                <mat-select [(ngModel)]="form.AccountId" name="account">
                    <mat-option *ngFor="let acc of Accounts" [value]="acc.AccountId">
                        {{acc.AccountNumber}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="row">
            <mat-form-field required style="margin: 5px;" appearance="fill">
                <mat-label>Field to Search</mat-label>
                <mat-select required [(ngModel)]="form.SearchFieldId" name="searchField">
                    <mat-option *ngFor="let sf of SearchFields" [value]="sf.SearchFieldId">
                        {{sf.SearchFieldDesc}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field required style="margin: 5px;" appearance="fill">
                <mat-label for="username">Search Value</mat-label>
                <input matInput type="text" class="form-control" name="searchValue" [(ngModel)]="form.SearchValue"
                    required #searchValue="ngModel" />

            </mat-form-field>
            <div style="display: inline-block;position: relative;text-align:left;margin-left:20px;">
                <button mat-raised-button (click)="searchPackages();">
                    Search
                </button>
            </div>
            <div  style="display: inline-block;position: relative;text-align:left;margin-left:20px;">
                <button mat-raised-button (click)="downloadSearch();">
                    Download Search
                </button>
            </div>
        </div>
        <div class="row">
            <mat-error class="alert alert-danger" role="alert" *ngIf="formErrors === true"
                style="white-space: pre-line">
                {{ errorMessage }}
            </mat-error>
        </div>
    </mat-card>
    <div fxLayout="row wrap">
        <mat-card [style.display]="!showTracking ? 'block' : 'none'"  style="margin: 20px;">
            <table mat-table #searchTable [dataSource]="Packages" matSort style="margin: 0px 20px 0px 20px;"
                class="mat-elevation-z8 total-avoid-table">
                <!-- Position Column -->
                <ng-container matColumnDef="airbill">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let element"><a [title]="element.AirbillID"
                            [routerLink]="['/airbillDetails', element.AirbillID]" target="_blank">
                            Details
                        </a> </td>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="AirbillNumber" >
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Tracking Number</th>
                    <td mat-cell *matCellDef="let element"> {{element.AirbillNumber}} </td>
                </ng-container>
                <ng-container matColumnDef="tracking-detail">
                    <th mat-header-cell *matHeaderCellDef>Tracking Info</th>
                    <td mat-cell *matCellDef="let element"> <button mat-button style="text-align: left;"
                            [style.display]="element.HasTracking ? 'block' : 'none'"
                            (click)="displayTracking(element.AirbillID)">
                            <mat-icon>track_changes</mat-icon>
                        </button> </td>
                </ng-container>
                <!-- Percentage Column -->
                <ng-container matColumnDef="CarrierServiceName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Service </th>
                    <td mat-cell *matCellDef="let element"> {{element.CarrierServiceName}} </td>
                </ng-container>

                <ng-container matColumnDef="TotalCharge">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Cost </th>
                    <td mat-cell *matCellDef="let element"> {{element.TotalCharge | currency}} </td>
                </ng-container>

                <ng-container matColumnDef="IncentiveAmount">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Incentive </th>
                    <td mat-cell *matCellDef="let element"> {{element.IncentiveAmount | currency}} </td>
                </ng-container>

                <!-- Report Link Column -->
                <ng-container matColumnDef="AccountNumber">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Account </th>
                    <td mat-cell *matCellDef="let element"> {{element.AccountNumber}} </td>
                </ng-container>

                <ng-container matColumnDef="InvoiceNumber">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Invoice </th>
                    <td mat-cell *matCellDef="let element"> {{element.InvoiceNumber}} </td>
                </ng-container>
                <ng-container matColumnDef="ShipperCompany">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Shipper Company </th>
                    <td mat-cell *matCellDef="let element"> {{element.ShipperCompany}} </td>
                </ng-container> 
                <ng-container matColumnDef="ShipperName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Shipper Name </th>
                    <td mat-cell *matCellDef="let element"> {{element.ShipperName}} </td>
                </ng-container> 

                <ng-container matColumnDef="ShipperAddress">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Shipper Address </th>
                    <td mat-cell *matCellDef="let element"> {{element.ShipperAddress}} </td>
                </ng-container> 

                <ng-container matColumnDef="ShipperCity">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Shipper City </th>
                    <td mat-cell *matCellDef="let element"> {{element.ShipperCity}} </td>
                </ng-container> 

                <ng-container matColumnDef="ShipperState">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Shipper State </th>
                    <td mat-cell *matCellDef="let element"> {{element.ShipperState}} </td>
                </ng-container> 

                <ng-container matColumnDef="ShipperZip">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Shipper Zip </th>
                    <td mat-cell *matCellDef="let element"> {{element.ShipperZip}} </td>
                </ng-container> 

                <ng-container matColumnDef="ShipperCountry">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Shipper Country </th>
                    <td mat-cell *matCellDef="let element"> {{element.ShipperCountry}} </td>
                </ng-container> 
                <ng-container matColumnDef="RecipCompany">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Recipient Company </th>
                    <td mat-cell *matCellDef="let element"> {{element.RecipCompany}} </td>
                </ng-container> 
                <ng-container matColumnDef="RecipName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Recipient Name </th>
                    <td mat-cell *matCellDef="let element"> {{element.RecipName}} </td>
                </ng-container> 

                <ng-container matColumnDef="RecipAddress">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Recipient Address </th>
                    <td mat-cell *matCellDef="let element"> {{element.RecipAddress}} </td>
                </ng-container> 

                <ng-container matColumnDef="RecipCity">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Recipient City </th>
                    <td mat-cell *matCellDef="let element"> {{element.RecipCity}} </td>
                </ng-container> 

                <ng-container matColumnDef="RecipState">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Recipient State </th>
                    <td mat-cell *matCellDef="let element"> {{element.RecipState}} </td>
                </ng-container> 

                <ng-container matColumnDef="RecipZip">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Recipient Zip </th>
                    <td mat-cell *matCellDef="let element"> {{element.RecipZip}} </td>
                </ng-container> 

                <ng-container matColumnDef="RecipCountry">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Recipient Country </th>
                    <td mat-cell *matCellDef="let element"> {{element.RecipCountry}} </td>
                </ng-container> 

                <ng-container matColumnDef="ReferenceNumber">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Reference Number </th>
                    <td mat-cell *matCellDef="let element"> {{element.ReferenceNumber}} </td>
                </ng-container> 

                <ng-container matColumnDef="PickupRecordNumber">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Pickup Record Number </th>
                    <td mat-cell *matCellDef="let element"> {{element.PickupRecordNumber}} </td>
                </ng-container> 

                <ng-container matColumnDef="PickupDate">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Sent </th>
                    <td mat-cell *matCellDef="let element"> {{element.PickupDate | date: 'short'}} </td>
                </ng-container>

                <ng-container matColumnDef="SignedBy">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Signed By </th>
                    <td mat-cell *matCellDef="let element"> {{element.SignedBy}} </td>
                </ng-container>

                <ng-container matColumnDef="DeliveryDate">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Delivery </th>
                    <td mat-cell *matCellDef="let element"> {{element.DeliveryDate | date: 'short'}} </td>
                </ng-container>

                <ng-container matColumnDef="Deadline">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Deadline </th>
                    <td mat-cell *matCellDef="let element"> {{element.Deadline | date: 'short'}} </td>
                </ng-container>

                <ng-container matColumnDef="Weight">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Weight </th>
                    <td mat-cell *matCellDef="let element"> {{element.Weight}} </td>
                </ng-container> 

                <ng-container matColumnDef="WeightUnits">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Weight Units </th>
                    <td mat-cell *matCellDef="let element"> {{element.WeightUnits}} </td>
                </ng-container> 

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="viewAirbill(row)"></tr>

            </table>
            <mat-paginator #paginator [pageSizeOptions]="[5, 10, 20]" style="width:40%;margin: 0px 20px 0px 20px;"
                showFirstLastButtons>
            </mat-paginator>
        </mat-card>
        <mat-card fxFlex="100%" [style.display]="showTracking ? 'block' : 'none'" style="margin: 20px;" id="trackingGrid">
            <div fxflex="row" style="margin: 20px;" data-html2canvas-ignore="true">
                <button fxFlex="20%" mat-raised-button (click)="openPDF()">Download PDF</button>
                <button fxFlex="20%" mat-raised-button (click)="downloadTracking(currentAirbill?.AirbillNumber);">Export to Excel</button>
                    <button fxFlex="20%" mat-raised-button (click)="hideTracking();">
                        <mat-icon>exit_to_app</mat-icon>
                        Close
                    </button>
            </div>
            <div fxflex="row" style="margin: 20px;">
                
                    <mat-label fxFlex="30%">Delivery Date: {{currentAirbill?.DeliveryDate | date: 'short'}}</mat-label>
                 
                    <mat-label fxFlex="30%">Signed By: {{currentAirbill?.SignedBy}}</mat-label>
                    
                <!-- <mat-form-field class="form-group" style="padding-right: 50px;" >
                    <mat-label for="DeliveryDate">Delivery Date</mat-label>
                    <input *ngIf="currentAirbill" disabled matInput type="text" class="form-control" name="DeliveryDate" [(ngModel)]="currentAirbill.DeliveryDate"
                        #DeliveryDate="ngModel" />
                </mat-form-field> -->

                <!-- <mat-form-field class="form-group">
                    <mat-label for="SignedBy">Tracking Number</mat-label>
                    <input matInput *ngIf="currentAirbill" type="text" class="form-control" name="SignedBy" [(ngModel)]="currentAirbill.SignedBy"
                        disabled #SignedBy="ngModel" />
                </mat-form-field>  -->
                
            </div>
            <table mat-table [dataSource]="Tracking" style="width:90%;margin-left:20px;"
                class="mat-elevation-z8 tracking-table">

                <!-- Position Column -->
                <ng-container matColumnDef="airbill">
                    <th mat-header-cell *matHeaderCellDef> Scan Time </th>
                    <td mat-cell *matCellDef="let element">
                        {{element.ScanTime | date: 'short'}} </td>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="tracking-number">
                    <th mat-header-cell *matHeaderCellDef>Scan Desc</th>
                    <td mat-cell *matCellDef="let element"> {{element.EventDescription}} </td>
                </ng-container>

                <!-- Percentage Column -->
                <ng-container matColumnDef="service">
                    <th mat-header-cell *matHeaderCellDef> City </th>
                    <td mat-cell *matCellDef="let element"> {{element.City}} </td>
                </ng-container>

                <!-- Report Link Column -->
                <ng-container matColumnDef="acount">
                    <th mat-header-cell *matHeaderCellDef> State </th>
                    <td mat-cell *matCellDef="let element"> {{element.State}} </td>
                </ng-container>

                <ng-container matColumnDef="invoice">
                    <th mat-header-cell *matHeaderCellDef> Zip </th>
                    <td mat-cell *matCellDef="let element"> {{element.Zip}} </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedTrackingColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedTrackingColumns;"></tr>

            </table>
            <mat-paginator #trackingPaginator [pageSize]="50" [pageSizeOptions]="[5, 10, 20, 50]"
                style="width:95%;margin: 0px 20px 0px 20px;" showFirstLastButtons>
            </mat-paginator>
        </mat-card>
    </div>
</div>