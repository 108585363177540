import { number } from "echarts"

export interface UserInfo {
    ClientId: number;
    FirstName: string;
    LastName: string;
    Email: string;
    ResetToken: string;
    TokenAdded: string;
    UserName: string;
    CompanyName: string;
    Password: string;
    Occupation: string;
    Address: string;
    City: string;
    State: string;
    Zip: string;
    UserType: string;
}

export interface ApiResponse {
    StatusCode: number,
    Value: any,
    Formatters: any,
    ContentTypes: any,
    DeclaredType: any
}

export interface StandardApiRequest {
    AccountId: number,
    CarrierId: number,
    StartDate: Date,
    EndDate: Date,
    IsPageLoad: boolean,
    ClientId: number
}

export interface SearchApiRequest {
    AccountId: number,
    CarrierId: number,
    StartDate: Date,
    EndDate: Date,
    SearchFieldId: number,
    SearchValue: string,
    ClientId: number
}

export interface ReportApiRequest {
    AccountId: number,
    CarrierId: number,
    StartDate: Date,
    EndDate: Date,
    IsPageLoad: boolean,
    ClientId: number,
    ReportId: number
}

export interface Report {
    ReportId: number,
    ReportName: string
}

export interface DashboardStats {
    StartDate: Date,
    EndDate: Date,
    AverageZone_Profit: number,
    AverageZone_Amount: number,
    FreightSpend_FedexEffectiveDiscount: number,
    FreightSpend_UPSEffectiveDiscount: number,
    FreightSpend_Profit: number,
    FreightSpend_Count: number,
    FreightSpend_Amount: number,
    Surcharges_Profit: number,
    Surcharges_Count: number,
    Surcharges_Amount: number,
    NetSpend_Profit: number,
    NetSpend_Count: number,
    NetSpend_Amount: number,
    AccountId: number,
    CarrierId: number,
    WeightCostPerPackage: number,
}

export interface NetCharge {
    ServiceGroupID: number
    ServiceGroupName: string
    PackageCount: number
    NetFreight: number
    NetCharge: number
    Incentive: number
    RatedWeight: number
    EffectiveDiscount: number
}

export interface SurchargeByType {
    ServiceGroupName: string
    NetCharge: number
}

export interface PackageCountByWeight {
    CarrierId: number,
    CarrierName: string,
    WeightBucketName: string,
    WeightBucketId: number,
    PackageCount: number
}

export interface PackageCountByZone {
    ZoneSN: number,
    ServiceGroupName: string,
    ServiceGroupId: number,
    PackageCount: number,
    NetCharge: number
}

export interface PackagesByStatesTo {
    RecipState: string,
    RecipCountry: string,
    Packages: number
}

export interface PackagesByStatesFrom {
    ShipperState: string,
    ShipperCountry: string,
    Packages: number
}

export interface Carrier {
    CarrierId: number,
    CarrierName: string
}

export interface Account {
    AccountId: number,
    AccountNumber: string
}

export interface SearchField {
    SearchFieldId: number,
    SearchFieldDesc: string
}

export class ChartDataModel {
    public labels: string[] = [];
    public values: any = [];
}

export interface RefundTotalByDisputeType {
    CarrierId: number,
    DisputeType: string,
    RefundTotal: number
}

export interface Airbill {
    InvoiceID: number,
    AccountNumber: string,
    InvoiceNumber: string,
    HasTracking: boolean,
    AirbillID: number,
    AirbillNumber: string,
    ChargeTypeID: number,
    ShipperName: string,
    ShipperCompany: string,
    ShipperAddress: string,
    ShipperAddress2: string,
    ShipperAddress3: string,
    ShipperCity: string,
    ShipperState: string,
    ShipperZip: string,
    ShipperCountry: string,
    RecipName: string,
    RecipCompany: string,
    RecipAddress: string,
    RecipAddress2: string,
    RecipAddress3: string,
    RecipCity: string,
    RecipState: string,
    RecipZip: string,
    RecipCountry: string,
    RegionCode: string,
    PaymentTypeID: number,
    ReferenceNumber: string,
    SignedBy: string,
    PickupRecordNumber: string,
    PickupDate: Date,
    ServiceID: number,
    AreaID: number,
    Zone: string,
    FirstAttempt: Date,
    DeliveryDate: Date,
    ResidentialDelivery: boolean,
    SaturdayDelivery: boolean,
    Weight: number,
    WeightUnits: string,
    DimensionalWeightIndicator: boolean,
    Oversize1Indicator: boolean,
    Oversize2Indicator: boolean,
    CODIndicator: boolean,
    AdditionalHandlingIndicator: boolean,
    ExtendedDestinationIndicator: boolean,
    InvalidAccountChargeIndicator: boolean,
    DeliveryAreaSurchargeIndicator: boolean,
    ChargebackSurchargeIndicator: boolean,
    PackageTypeID: number,
    Pieces: number,
    TransportationCharge: number,
    Discount: number,
    FuelSurcharge: number,
    ResidentialCharge: number,
    SaturdayCharge: number,
    AddressCorrectionCharge: number,
    DeclaredValueCharge: number,
    InaccessibleDangerousGoodsCharge: number,
    AccessibleDangerousGoodsCharge: number,
    DeliveryAreaSurcharge: number,
    ExtraServiceCharge: number,
    IncentiveAmount: number,
    TotalCharge: number,
    BalanceDue: number,
    StatusID: number,
    TransitStatusID: number,
    Deadline: Date,
    ServiceFailureID: number,
    ReturnService: boolean,
    ContentsDescription: string,
    Accepted: boolean,
    LastTracked: Date,
    Generated: Date,
    GeneratedFromRecordID: number,
    PublishedRate: number,
    CarrierServiceName: string,
    DamagedDelivery: boolean,
    GSRDaysInTransit: number,
    AddressID: number,
    MultiPack: boolean,
    DiscountPerformancePricing: number,
    EarnedDiscount: number,
    GraceDiscount: number,
    VolumeDiscount: number,
    AutomationBonusDiscount: number,
    DropOffDiscount: number,
    Discount1: number,
    RatedWeight: string;
    EnteredWeightUnits: string,
    BilledWeightUnits: string,
    PublicRate: number,
    BASISVALUE: number,
    BASISCURRENCYCODE: string,
    WeightBucketID: number,
    ZipTypeID: number,
    GroundServiceCode: string,
    BundleNumber: string,
    NetFreightCharge: number,
    MultiweightIndicator: boolean,
    DirectionID: number,
    ChargeCategoryCodeId: number,
}

export interface TrackingException {
    ScanCodeReason: string,
    ExceptionCount: number
}

export interface PerformanceByService {
    ServiceGroupName: string,
    TotalPackage: number,
    RawLate: number,
    OntimePercentage: number
}

export interface PerformanceStats {
    TotalPackage: number,
    RawLate: number,
    OntimePercentage: number,
    TotalRefunds: number,
    NonInvoicedRefunds : number
}

export interface ClientInvoice {
    NUMBER: number,
    ClientInvoiceID: number,
    InvoiceDate: Date,
    AmountDue: number,
    AmountPaid: number,
    AmountUnpaid: number,
    ClientID: number,
    RefundFee: number,
    Adjustments: number,
    AdjustmentDesc: string,
    MiscCharges: number,
    MiscChargeDesc: string,
    PastDue: number,
    ClientName: string,
    CompanyName: string,
    RepID: number,
    CommissionAmount: number,
    PaymentAllocationsCount: number,
}

export interface CarrierInvoice
	{
		NUMBER: number,
		InvoiceID: number,
		InvoiceDate: Date,
		InvoiceNumber: string,
		InvoiceAmount: number,
		AccountID: number,
		ClientID: number,
		AccountName: string,
		AccountNumber: string 
    }

export interface ClientInvoiceRequest {
    ClientId: number,
    PageIndex: number,
    PageSize: number,
    SortColumn: string,
    SortOrder: string,
    RepId: number
}

export interface DownloadClientInvoiceRequest {
    ClientInvoiceID: number
}

export interface AirbillRequest {
    AirbillId: number
}

export interface AvoidableCharges {
    Description: string,
    ClientName: string,
    ChargeCount: number,
    Amount: number,
    ReportId: number,
    Info: string
}

export interface AvoidableChargesByWeek {
    Description: string,
    ClientName: string,
    ChargeCount: number,
    Amount: number,
    WeekDate: number,
    YearDate: string
}

export interface TrackingDataResponse
    {
        ScanTime: Date,
        EventDescription: string,
        City: string,
        State: string,
        Zip: string,
        SignedBy: string,
        DeliveryDate: Date
    }

export interface TotalNetCharge
{
    Year: string,
    Month: string,
    NetCharge: number
}

export interface TotalWeight
{
    Year: string,
    Month: string,
    Weight: number
}

export interface SurchargeTotal
{
    Year: string,
    Month: string,
    TotalSurcharge: number
}
export interface CostByMonth
{
    Year: string,
    Month: string,
    Cost: number
}

export interface RefundsByMonth
{
    Year: string,
    Month: string,
    TotalRefunds: number
}

export interface Interfaces {
}
