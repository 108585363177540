<div class="row">
    <mat-card style="margin: 20px;">
        <mat-card-title>FILTERS</mat-card-title>
        <mat-form-field style="margin: 5px;" appearance="fill">
            <mat-label>Start Date</mat-label>
            <input matInput [matDatepicker]="startdate" [(ngModel)]="form.StartDate">
            <mat-datepicker-toggle matSuffix [for]="startdate"></mat-datepicker-toggle>
            <mat-datepicker #startdate></mat-datepicker>
        </mat-form-field>
        <mat-form-field style="margin: 5px;" appearance="fill">
            <mat-label>End Date</mat-label>
            <input matInput [matDatepicker]="enddate" [(ngModel)]="form.EndDate">
            <mat-datepicker-toggle matSuffix [for]="enddate"></mat-datepicker-toggle>
            <mat-datepicker #enddate></mat-datepicker>
        </mat-form-field>
        <mat-form-field style="margin: 5px;" appearance="fill">
            <mat-label>Carrier</mat-label>
            <mat-select [(ngModel)]="form.CarrierId" name="carrier" (change)="updateAccountsList()">
                <mat-option *ngFor="let car of Carriers" [value]="car.CarrierId">
                    {{car.CarrierName}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field style="margin: 5px;" appearance="fill">
            <mat-label>Account</mat-label>
            <mat-select [(ngModel)]="form.AccountId" name="account">
                <mat-option *ngFor="let acc of Accounts" [value]="acc.AccountId">
                    {{acc.AccountNumber}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <div style="display: inline-block;position: relative;text-align:left;margin-left:20px;">
            <button mat-raised-button (click)="updateDashboardComponents();">
                Update
            </button>
        </div>
    </mat-card>
    <div fxLayout="row wrap">
        <div fxFlex="50%" fxFlex.md="100%" fxFlex.xs="100%" fxFlex.sm="100%">
            <mat-card class="dashboard-card">
                <mat-card-title>TOTAL NET CHARGE<mat-icon (click)="goToLink('totalnetbymonth')" style="float: right;">bar_chart</mat-icon></mat-card-title>
                <div class="chart-wrapper" [ng2-loading]="netChargeLoading">
                    <canvas baseChart [datasets]="totalNetChargeChartData" [labels]="totalNetChargeChartLabels"
                        [options]="totalNetChargeChartOptions" [legend]="totalNetChargeChartLegend" [chartType]="totalNetChargeChartType" [colors]="totalNetChargeChartColors">
                    </canvas>
                </div>
            </mat-card>
        </div>
        <div fxFlex="50%" fxFlex.md="100%" fxFlex.xs="100%" fxFlex.sm="100%">
            <mat-card class="dashboard-card">
                <mat-card-title>TOTAL WEIGHT (Lbs.)<mat-icon (click)="goToLink('totalweightbymonth')" style="float: right;">bar_chart</mat-icon></mat-card-title>
                <mat-card-content>
                    <div class="chart-wrapper" [ng2-loading]="weightLoading">
                        <canvas baseChart [data]="totalWeightChartData" [labels]="totalWeightChartLabels" [options]="totalWeightChartOptions"
                            [chartType]="totalWeightChartType" [legend]="totalWeightChartLegend" [colors]="totalWeightChartColors"> 
                        </canvas>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
    <div fxLayout="row wrap">
        <div fxFlex="50%" fxFlex.md="100%" fxFlex.xs="100%" fxFlex.sm="100%">
            <mat-card class="dashboard-card">
                <mat-card-title>TOTAL FREIGHT CHARGE<mat-icon (click)="goToLink('totalfreightbymonth')" style="float: right;">bar_chart</mat-icon></mat-card-title>
                <mat-card-content>
                    <div class="chart-wrapper" [ng2-loading]="freightLoading">
                        <canvas baseChart [data]="totalFreightChargeChartData" [labels]="totalFreightChargeChartLabels" [options]="totalFreightChargeChartOptions"
                            [chartType]="totalFreightChargeChartType" [legend]="totalFreightChargeChartLegend" [colors]="totalFreightChargeChartColors">
                        </canvas>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
        <div fxFlex="50%" fxFlex.md="100%" fxFlex.xs="100%" fxFlex.sm="100%">
            <mat-card class="dashboard-card">
                <mat-card-title>SURCHARGE TOTAL<mat-icon (click)="goToLink('surchargebymonth')" style="float: right;">bar_chart</mat-icon></mat-card-title>
                <div class="chart-wrapper" [ng2-loading]="surchargeLoading">
                    <canvas baseChart [datasets]="surchargeTotalChartData"
                        [labels]="surchargeTotalChartLabels" [options]="surchargeTotalChartOptions"
                        [legend]="surchargeTotalChartLegend" [chartType]="surchargeTotalChartType"
                        [colors]="surchargeTotalChartColors">
                    </canvas>
                </div>
            </mat-card>
        </div>
    </div>
    <div fxLayout="row wrap">
        <div fxFlex="50%" fxFlex.md="100%" fxFlex.xs="100%" fxFlex.sm="100%">
            <mat-card class="dashboard-card">
                <mat-card-title>COST PER PACKAGE<mat-icon (click)="goToLink('costbypackage')" style="float: right;">bar_chart</mat-icon></mat-card-title>
                <div class="chart-wrapper" [ng2-loading]="costPackageLoading">
                    <canvas baseChart [datasets]="costPerPackageChartData" [labels]="costPerPackageChartLabels"
                        [options]="costPerPackageChartOptions" [legend]="costPerPackageChartLegend" 
                        [chartType]="costPerPackageChartType" [colors]="costPerPackageChartColors">
                    </canvas>
                </div>
            </mat-card>
        </div>
        <div fxFlex="50%" fxFlex.md="100%" fxFlex.xs="100%" fxFlex.sm="100%">
            <mat-card class="dashboard-card">
                <mat-card-title>COST PER POUND<mat-icon (click)="goToLink('costbypound')" style="float: right;">bar_chart</mat-icon></mat-card-title>
                <mat-card-content>
                    <div class="chart-wrapper" [ng2-loading]="costPoundLoading">
                        <canvas baseChart [data]="costPerPoundChartData" [labels]="costPerPoundChartLabels" 
                            [options]="costPerPoundChartOptions" [colors]="costPerPoundChartColors"
                            [chartType]="costPerPoundChartType" [legend]="costPerPoundChartLegend">
                        </canvas>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>