import { Component, OnInit } from '@angular/core';
import { TokenStorageService } from '../services/token-storage.service'

@Component({
  selector: 'app-dashboard-header',
  templateUrl: './dashboard-header.component.html',
  styleUrls: ['./dashboard-header.component.css']
})
export class DashboardHeaderComponent implements OnInit {

  constructor( private tokenStorage: TokenStorageService) { }

  ngOnInit(): void {
  }

}
