import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { ChartOptions } from 'chart.js';
import * as fileSaver from 'file-saver';
import { appConstants } from '../helpers/constants';
import { StandardApiRequest, Carrier, Account, RefundTotalByDisputeType, PerformanceByService, TrackingException, UserInfo, SurchargeTotal } from '../helpers/interfaces';
import { ApiService } from '../services/api.service.service';
import { SessionService } from '../services/session.service';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-performance-by-service-chart',
  templateUrl: './performance-by-service-chart.component.html',
  styleUrls: ['./performance-by-service-chart.component.css']
})
export class PerformanceByServiceChartComponent implements OnInit {
  constructor(private apiService: ApiService,  private notification: MatSnackBar, private sessionService: SessionService, private userService: UserService) { }

  displayedColumns: string[] = ['month', 'year'];
  dataTable: MatTableDataSource<PerformanceByService> = new MatTableDataSource();
  
  userInfo!: UserInfo;
  dateToday: Date = new Date();
  form: StandardApiRequest = {
    StartDate: new Date(this.dateToday.getFullYear(), 1, 1),
    EndDate: new Date(this.dateToday),
    CarrierId: 0,
    AccountId: 0,
    ClientId: 0,
    IsPageLoad: false
  }

  defaultScale = appConstants.fun.getChartScale('Month', 'Amount in Dollars');

  servicePerformance: any = {
    option: {
      responsive: true,
      isLoading: false,
      scales: {
        xAxes: [{
          ticks: {
            beginAtZero: true
          },
          scaleLabel: {
            display: true,
            labelString: 'Percentage On-Time'
          }
        }]
      },
      tooltips: {
        enabled: true,
        mode: 'single',
        callbacks: {
          label: function (tooltipItems: { yLabel: any, xLabel: any; }, data: any) {
            return tooltipItems.xLabel + ' %';
          }
        }
      },

    },
    label: null,
    type: appConstants.CHARTS.TYPES.HORIZONTAL_BAR,

    legend: false,
    data: [{
      data: []
    }],


  };

  servicePerformanceChartColors: Array<any> = [{}];
  servicePerformanceLoading: boolean = true;

  Carriers: Carrier[] = [{ CarrierId: 0, CarrierName: "All" }]
  Accounts: Account[] = [{ AccountId: 0, AccountNumber: "All" }]

  ngOnInit(): void {
    this.userInfo = this.userService.userInfo;
    this.sessionService.performanceForm.ClientId = this.userInfo.ClientId;
    this.form = this.sessionService.performanceForm;
    
    this.apiService.makeStandardApiCall('user/GetCarriersByClient', this.form).subscribe(
      (data: any) => {
        if (data.StatusCode != 401) {
          const carriers: Carrier[] = JSON.parse(data.Value);
          this.Carriers = [];
          this.Carriers.push({ CarrierName: 'All', CarrierId: 0 });
          carriers.forEach(element => {
            this.Carriers.push(element);
          });
        }
      }
    );
    this.updateDashboardComponents();
    this.updateAccountsList();
  }

  updateAccountsList(): void {
    this.apiService.makeStandardApiCall('user/GetAccountsByClientCarrier', this.form).subscribe(
      (data: any) => {
        if (data.StatusCode != 401) {
          const accounts: Account[] = JSON.parse(data.Value);
          this.Accounts = [];
          this.Accounts.push({ AccountNumber: 'All', AccountId: 0 });
          accounts.forEach(element => {
            this.Accounts.push({ AccountNumber: element.AccountNumber.trim().toUpperCase(), AccountId: element.AccountId });
          });
        }
      }
    );
  }

  updateDashboardComponents(): void {
    this.sessionService.performanceForm = this.form;
   
    this.servicePerformanceLoading = true;
    this.apiService.makeStandardApiCall('values/GetPerformanceByService', this.form).subscribe(
      (data: any) => {
        const labelsTemp: Array<string> = [];
        const valuesTemp: Array<number> = [];
        const returnData: Array<PerformanceByService> = JSON.parse(data.Value);
        this.dataTable = new MatTableDataSource<PerformanceByService>(JSON.parse(data.Value));
        // Populate
        returnData.forEach(element => {
          labelsTemp.push(element.ServiceGroupName);
					valuesTemp.push(element.OntimePercentage);
        });

				// Set values to chart
				this.servicePerformance.label = labelsTemp;
				this.servicePerformance.data[0].data = valuesTemp;
        this.servicePerformance.option.tooltips.callbacks;
        this.servicePerformanceChartColors = [
					{
						backgroundColor: 'rgb(102,163,255,1)'
					}
				];
        this.servicePerformanceLoading = false
      }
    );
  }

  downloadSearch(): void {
    this.apiService.downloadFile('GetPerformanceByServiceDownload', this.form).subscribe((response: any) => { //when you use stricter type checking
      let blob: any = new Blob([response], { type: 'application/octet-stream' });
      const url = window.URL.createObjectURL(blob);
      fileSaver.saveAs(blob, 'PerformanceByService.xlsx');
    }), (error: any) => 
      () => this.notification.open('File downloaded successfully');
  }
}
