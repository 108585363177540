
<!-- routes will be rendered here -->

<app-dashboard-menu></app-dashboard-menu>


<!--<div class="content" role="main">

  <mat-form-field appearance="fill">
    <mat-label>Username</mat-label>
    <input matInput>
  </mat-form-field>
  <mat-form-field appearance="fill">
    <mat-label>Password</mat-label>
    <input matInput type="password">
  </mat-form-field>
  
  </div>-->
