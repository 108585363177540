<div class="row">
    <mat-card style="margin: 20px;">
        <mat-card-title>FILTERS</mat-card-title>
        <mat-form-field style="margin: 5px;" appearance="fill">
            <mat-label>Start Date</mat-label>
            <input matInput [matDatepicker]="startdate" [(ngModel)]="form.StartDate">
            <mat-datepicker-toggle matSuffix [for]="startdate"></mat-datepicker-toggle>
            <mat-datepicker #startdate></mat-datepicker>
        </mat-form-field>
        <mat-form-field style="margin: 5px;" appearance="fill">
            <mat-label>End Date</mat-label>
            <input matInput [matDatepicker]="enddate" [(ngModel)]="form.EndDate">
            <mat-datepicker-toggle matSuffix [for]="enddate"></mat-datepicker-toggle>
            <mat-datepicker #enddate></mat-datepicker>
        </mat-form-field>
        <mat-form-field style="margin: 5px;" appearance="fill">
            <mat-label>Carrier</mat-label>
            <mat-select [(ngModel)]="form.CarrierId" name="carrier" (change)="updateAccountsList()">
                <mat-option *ngFor="let car of Carriers" [value]="car.CarrierId">
                    {{car.CarrierName}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field style="margin: 5px;" appearance="fill">
            <mat-label>Account</mat-label>
            <mat-select [(ngModel)]="form.AccountId" name="account">
                <mat-option *ngFor="let acc of Accounts" [value]="acc.AccountId">
                    {{acc.AccountNumber}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <div style="display: inline-block;position: relative;text-align:left;margin-left:20px;">
            <button mat-raised-button (click)="updateDashboardComponents();">
                Update
            </button>
        </div>
    </mat-card>

    <div fxLayout="row wrap" [ng2-loading]="statsLoading">
        <div fxFlex="20%" fxFlex.xs="100%" fxFlex.sm="100%" fxFlex.md="50%">
            <mat-card class="dashboard-card">
                <div fxLayout="row" class="mat-sub-div">
                    <mat-card-subtitle>Total Packages</mat-card-subtitle>
                </div>
                <div fxLayout="row">
                    <h2>
                        <mat-label>{{ performanceStats.TotalPackage | number}}</mat-label>
                    </h2>
                </div>
            </mat-card>
        </div>
        <div fxFlex="20%" fxFlex.xs="100%" fxFlex.sm="100%" fxFlex.md="50%">
            <mat-card class="dashboard-card">
                <div fxLayout="row" class="mat-sub-div">
                    <mat-card-subtitle>Late Deliveries</mat-card-subtitle>
                </div>
                <div fxLayout="row">
                    <h2>
                        <mat-label>{{ performanceStats.RawLate | number}}</mat-label>
                    </h2>
                </div>
            </mat-card>
        </div>
        <div fxFlex="20%" fxFlex.xs="100%" fxFlex.sm="100%" fxFlex.md="50%">
            <mat-card class="dashboard-card">
                <div fxLayout="row" class="mat-sub-div">
                    <mat-card-subtitle>% On Time</mat-card-subtitle>
                </div>
                <div fxLayout="row">
                    <h2>
                        <mat-label>{{ performanceStats.OntimePercentage | percent }}</mat-label>
                    </h2>
                </div>
            </mat-card>
        </div>
        <div fxFlex="20%" fxFlex.xs="100%" fxFlex.sm="100%" fxFlex.md="50%">
            <mat-card class="dashboard-card">
                <div fxLayout="row" class="mat-sub-div">
                    <mat-card-subtitle>Total Refunds(Invoiced)</mat-card-subtitle>
                </div>
                <div fxLayout="row">
                    <h2>
                        <mat-label>{{ performanceStats.TotalRefunds | currency}}</mat-label>
                    </h2>
                </div>
            </mat-card>
        </div>
        <div fxFlex="20%" fxFlex.xs="100%" fxFlex.sm="100%" fxFlex.md="50%">
            <mat-card class="dashboard-card">
                <div fxLayout="row" class="mat-sub-div">
                    <mat-card-subtitle>Refunds Not Invoiced</mat-card-subtitle>
                </div>
                <div fxLayout="row">
                    <h2>
                        <mat-label>{{ performanceStats.NonInvoicedRefunds | currency}}</mat-label>
                    </h2>
                </div>
            </mat-card>
        </div>
    </div>

    <div fxLayout="row wrap">
        <div fxFlex="50%" fxFlex.md="100%" fxFlex.xs="100%" fxFlex.sm="100%">
            <mat-card class="dashboard-card">
                <mat-card-title>TOTAL REFUNDS BY MONTH<mat-icon (click)="goToLink('refundsbymonth')" style="float: right;">bar_chart</mat-icon></mat-card-title>
                <div class="chart-wrapper" [ng2-loading]="totalRefundsLoading">
                    <canvas baseChart [datasets]="totalRefundsByMonthData" [labels]="totalRefundsByMonthLabels"
                        [options]="totalRefundsByMonthOptions" [legend]="totalRefundsByMonthLegend" [chartType]="totalRefundsByMonthType"
                        [colors]="totalRefundsByMonthColors">
                    </canvas>
                </div>
            </mat-card>
        </div>
        <div fxFlex="50%" fxFlex.md="100%" fxFlex.xs="100%" fxFlex.sm="100%">
            <mat-card class="dashboard-card">
                <mat-card-title>REFUND TOTAL<mat-icon (click)="goToLink('refundstotal')" style="float: right;">bar_chart</mat-icon></mat-card-title>
                <mat-card-content>
                    <div class="chart-wrapper" [ng2-loading]="refundTotalLoading">
                        <canvas baseChart [data]="refundTotalChartData" [labels]="refundTotalChartLabels" [options]="refundTotalChartOptions"
                            [chartType]="refundTotalChartType" [colors]="refundTotalChartColors"> 
                        </canvas>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
    <div fxLayout="row wrap">
        <div fxFlex="50%" fxFlex.md="100%" fxFlex.xs="100%" fxFlex.sm="100%">
            <mat-card class="dashboard-card">
                <mat-card-title>ON TIME PERFORMANCE BY SERVICE<mat-icon (click)="goToLink('performancebyservice')" style="float: right;">bar_chart</mat-icon></mat-card-title>
                <mat-card-content>
                    <div class="chart-wrapper" [ng2-loading]="servicePerformanceLoading">
                        <canvas baseChart [datasets]="servicePerformance.data"
                        [labels]="servicePerformance.label"
                        [options]="servicePerformance.option"
                        [legend]="servicePerformance.legend"
                        [colors]="servicePerformanceChartColors"
                        [chartType]="servicePerformance.type">
                        </canvas>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
        <div fxFlex="50%" fxFlex.md="100%" fxFlex.xs="100%" fxFlex.sm="100%">
            <mat-card class="dashboard-card">
                <mat-card-title>TRACKING EXCEPTIONS<mat-icon (click)="goToLink('trackingexceptions')" style="float: right;">bar_chart</mat-icon></mat-card-title>
                <div class="chart-wrapper" [ng2-loading]="trackingExceptionsLoading">
                    <canvas baseChart 
                    [datasets]="trackingException.data"
                    [labels]="trackingException.label"
                    [options]="trackingException.option"
                    [legend]="trackingException.legend"
                    [colors]="trackingExceptionChartColors"
                    [chartType]="trackingException.type">
                    </canvas>
                </div>
            </mat-card>
        </div>
    </div>
</div>
