import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import * as fileSaver from 'file-saver';
import { appConstants } from '../helpers/constants';
import { StandardApiRequest, Carrier, Account, UserInfo, TotalNetCharge } from '../helpers/interfaces';
import { ApiService } from '../services/api.service.service';
import { SessionService } from '../services/session.service';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-total-freight-month-chart',
  templateUrl: './total-freight-month-chart.component.html',
  styleUrls: ['./total-freight-month-chart.component.css']
})
export class TotalFreightMonthChartComponent implements OnInit {

  constructor(private apiService: ApiService,  private notification: MatSnackBar, private router: Router, private sessionService: SessionService, private userService: UserService) { }

  displayedColumns: string[] = ['month', 'year', 'net-charge'];
  dataTable: MatTableDataSource<TotalNetCharge> = new MatTableDataSource();
  
  defaultScale = appConstants.fun.getChartScale('Month', 'Amount in Dollars');
  costPerPoundScale = appConstants.fun.getChartScale('Month', 'Amount in Dollars/lb.')
  totalWeightScale = appConstants.fun.getChartScale('Month', 'Weight in Pounds')

  userInfo!: UserInfo;
  dateToday: Date = new Date();
  form: StandardApiRequest = {
    StartDate: new Date(this.dateToday.getFullYear(), 1, 1),
    EndDate: new Date(this.dateToday),
    CarrierId: 0,
    AccountId: 0,
    ClientId: 0,
    IsPageLoad: false
  }

  Carriers: Carrier[] = [{ CarrierId: 0, CarrierName: "All" }]
  Accounts: Account[] = [{ AccountId: 0, AccountNumber: "All" }]
  freightLoading: boolean = true;
 

  // [TOTAL FRIGHT CHARGE BY MONTH]
  totalFreightChargeChartOptions: any = {
    responsive: true,
    isLoading: true,
    scales: this.defaultScale,
    tooltips: {
      enabled: true,
      mode: 'single',
      callbacks: {
        label: function (tooltipItems: { yLabel: string; }, data: any) {
          return ' $' + tooltipItems.yLabel;
        }
      }
    }
  };
  totalFreightChargeChartLabels: string[] = [];
  totalFreightChargeChartType: any = appConstants.CHARTS.TYPES.VERTICAL_BAR;
  totalFreightChargeChartLegend: boolean = false;
  totalFreightChargeChartData: Array<any> = [{ data: null }];
  public totalFreightChargeChartColors = [{}];
 
  ngOnInit(): void {
    this.userInfo = this.userService.userInfo;
    this.sessionService.trendsForm.ClientId = this.userInfo.ClientId;
    this.form = this.sessionService.trendsForm;

    this.apiService.makeStandardApiCall('user/GetCarriersByClient', this.form).subscribe(
      (data: any) => {
        if (data.StatusCode != 401) {
          const carriers: Carrier[] = JSON.parse(data.Value);
          this.Carriers = [];
          this.Carriers.push({ CarrierName: 'All', CarrierId: 0 });
          carriers.forEach(element => {
            this.Carriers.push(element);
          });
        }
      }
    );
    this.updateDashboardComponents();
    this.updateAccountsList();
  }

  updateAccountsList(): void {
    this.apiService.makeStandardApiCall('user/GetAccountsByClientCarrier', this.form).subscribe(
      (data: any) => {
        if (data.StatusCode != 401) {
          const accounts: Account[] = JSON.parse(data.Value);
          this.Accounts = [];
          this.Accounts.push({ AccountNumber: 'All', AccountId: 0 });
          accounts.forEach(element => {
            this.Accounts.push({ AccountNumber: element.AccountNumber.trim().toUpperCase(), AccountId: element.AccountId });
          });
        }
      }
    );
  }

  updateDashboardComponents(): void {
    this.sessionService.trendsForm = this.form;
    this.freightLoading = true;
    this.apiService.makeStandardApiCall('values/GetTotalFreightChargesByMonth', this.form).subscribe(
      (data: any) => {
        const chartData = appConstants.fun.parseChartDataFromApi(JSON.parse(data.Value), 'NetCharge');
        this.dataTable = new MatTableDataSource<TotalNetCharge>(JSON.parse(data.Value));
        
        // Set values to chart
        this.totalFreightChargeChartLabels = chartData.labels;
        this.totalFreightChargeChartData = chartData.values;
        this.totalFreightChargeChartOptions.isLoading = false;
        this.totalFreightChargeChartOptions.tooltips.callbacks;
        this.totalFreightChargeChartColors = [
          {
            backgroundColor: 'rgb(102,163,255,1)',
          }
        ];
        this.freightLoading = false;
      }
    );
  }

  downloadSearch(): void {
    this.apiService.downloadFile('GetTotalFreightChargeDownload', this.form).subscribe((response: any) => { //when you use stricter type checking
      // const blob = new Blob([response], { type: 'application/octet-stream' });
      // const url= window.URL.createObjectURL(blob);
      // window.open(url);
      let blob: any = new Blob([response], { type: 'application/octet-stream' });
      const url = window.URL.createObjectURL(blob);
      fileSaver.saveAs(blob, 'TotalFreightCharge.xlsx');
    }), (error: any) => 
      () => this.notification.open('File downloaded successfully');
  }
}
