import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

const TOKEN_KEY = 'auth-token';
const USER_KEY = 'auth-user';

@Injectable({
  providedIn: 'root'
})
export class TokenStorageService {
  constructor() { 
    this.LoginStatus.subscribe((value) => {
      this.isLoggedIn = value
  });
  this.isLoggedIn = (this.getToken() != undefined)
  }

  isLoggedIn!: boolean | false;
  LoginStatus: Subject<boolean> = new Subject<boolean>();

  signOut(): void {
    window.sessionStorage.clear();
    this.LoginStatus.next(false);
  }

  toggleLoginStatus() {
    this.LoginStatus.next(!this.isLoggedIn);
  }
  public saveToken(token: string): void {
    window.sessionStorage.removeItem(TOKEN_KEY);
    window.sessionStorage.setItem(TOKEN_KEY, token);
    this.LoginStatus.next(true);
  }

  public getToken(): string | null {
    return window.sessionStorage.getItem(TOKEN_KEY);
  }

  public saveUser(user: any): void {
    window.sessionStorage.removeItem(USER_KEY);
    window.sessionStorage.setItem(USER_KEY, JSON.stringify(user));
  }

  public getUser(): any {
    const user = window.sessionStorage.getItem(USER_KEY);
    if (user) {
      return JSON.parse(user);
    }

    return {};
  }
}