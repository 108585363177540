import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiService } from './api.service.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private http: HttpClient, private apiService: ApiService) { }

  login(username: string, password: string): Observable<any> {
    return this.http.post(this.apiService.AUTH_API + 'user/Authenticate', {
      "Username": username,
      "Password": password
    }, this.apiService.httpOptions);
  }

  forgotpassword(username: string): Observable<any> {
    return this.http.post(this.apiService.AUTH_API + 'user/ForgotPassword', {
      "Username": username
    }, this.apiService.httpOptions);
  }

  getUserByToken(token: string): Observable<any> {
    return this.http.post(this.apiService.AUTH_API + 'user/UserByToken', {
      "Token": token
    }, this.apiService.httpOptions);
  }

  resetPassowrd(username: string, password: string, token: string): Observable<any> {
    return this.http.post(this.apiService.AUTH_API + 'user/ResetPassword', {
      "Username": username,
      "Password": password,
      "ResetToken": token
    }, this.apiService.httpOptions);
  }
}