import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import * as fileSaver from 'file-saver';
import { Label, MultiDataSet } from 'ng2-charts';
import { NetCharge, StandardApiRequest, Carrier, Account, UserInfo, SurchargeByType } from '../helpers/interfaces';
import { ApiService } from '../services/api.service.service';
import { SessionService } from '../services/session.service';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-surcharge-type-chart',
  templateUrl: './surcharge-type-chart.component.html',
  styleUrls: ['./surcharge-type-chart.component.css']
})
export class SurchargeTypeChartComponent implements OnInit {

  displayedColumns: string[] = ['service-group', 'net-charge'];
  surchargeTypeTable: MatTableDataSource<SurchargeByType> = new MatTableDataSource();
 
  surchargeOptions: ChartOptions = {
    responsive: true
  };

  surchargeLabels: Label[] = [];
  surchargeData: MultiDataSet = [
    [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
  ];
  doughnutChartType: ChartType = 'doughnut';

  basicOptions: any;
  constructor(private apiService: ApiService, private notification: MatSnackBar, private router: Router, private sessionService: SessionService, private userService: UserService) { }

  dateToday: Date = new Date();
  form: StandardApiRequest = {
    StartDate: new Date(new Date(Date.now() - 14 * 24 * 60 * 60 * 1000)),
    EndDate: new Date(this.dateToday),
    CarrierId: 0,
    AccountId: 0,
    ClientId: 0,
    IsPageLoad: false
  }

  userInfo!: UserInfo;
  apiData: StandardApiRequest = {
    StartDate: new Date(new Date(Date.now() - 14 * 24 * 60 * 60 * 1000)),
    EndDate: new Date(this.dateToday),
    CarrierId: 0,
    AccountId: 0,
    ClientId: 0,
    IsPageLoad: false
  };

  surchargesByType: SurchargeByType[] = []


  Carriers: Carrier[] = [{ CarrierId: 0, CarrierName: "All" }]
  Accounts: Account[] = [{ AccountId: 0, AccountNumber: "All" }]

  surchargesLoading: boolean = true;

  ngAfterViewInit() {


  }

  ngOnInit(): void {
    this.userInfo = this.userService.userInfo;
    this.sessionService.dashboardStatForm.ClientId = this.userInfo.ClientId;
    this.form = this.sessionService.dashboardStatForm;

    this.apiData.ClientId = this.userInfo.ClientId;
    this.apiService.makeStandardApiCall('user/GetCarriersByClient', this.apiData).subscribe(
      (data: any) => {
        if (data.StatusCode != 401) {
          const carriers: Carrier[] = JSON.parse(data.Value);
          this.Carriers = [];
          this.Carriers.push({ CarrierName: 'All', CarrierId: 0 });
          carriers.forEach(element => {
            this.Carriers.push(element);
          });
        }
      }
    );
    this.updateDashboardComponents();
    this.updateAccountsList();
  }

  onSubmit(): void {
    this.updateDashboardComponents();
  }

  updateAccountsList(): void {
    this.apiService.makeStandardApiCall('user/GetAccountsByClientCarrier', this.form).subscribe(
      (data: any) => {
        if (data.StatusCode != 401) {
          const accounts: Account[] = JSON.parse(data.Value);
          this.Accounts = [];
          this.Accounts.push({ AccountNumber: 'All', AccountId: 0 });
          accounts.forEach(element => {
            this.Accounts.push({ AccountNumber: element.AccountNumber.trim().toUpperCase(), AccountId: element.AccountId });
          });
        }
      }
    );
  }

  updateDashboardComponents(): void {
    this.apiData = {
      AccountId: this.form.AccountId,
      CarrierId: this.form.CarrierId,
      ClientId: this.userInfo.ClientId,
      StartDate: this.form.StartDate,
      EndDate: this.form.EndDate,
      IsPageLoad: false
    };

    this.sessionService.dashboardStatForm = this.form;

    this.surchargesLoading = true;
    this.apiService.makeStandardApiCall('values/GetSurchargeByTypes', this.apiData).subscribe(
      (data: any) => {
        if (data.StatusCode != 401) {
          this.surchargesByType = JSON.parse(data.Value);
          this.surchargeTypeTable = new MatTableDataSource<SurchargeByType>(JSON.parse(data.Value))
          const surchargeLabelsTemp: Label[] = [];
          const surchargeDataTemp: MultiDataSet = [
            []
          ];
          this.surchargesByType.forEach(element => {
            surchargeLabelsTemp.push(element.ServiceGroupName);
            surchargeDataTemp[0].push(element.NetCharge);
          });
          this.surchargeLabels = surchargeLabelsTemp;
          this.surchargeData = surchargeDataTemp;
        }
        this.surchargesLoading = false;
      }
    );

  }

  downloadSearch(): void {
    this.apiService.downloadSurchargeType(this.apiData).subscribe((response: any) => { //when you use stricter type checking
      // const blob = new Blob([response], { type: 'application/octet-stream' });
      // const url= window.URL.createObjectURL(blob);
      // window.open(url);
      let blob: any = new Blob([response], { type: 'application/octet-stream' });
      const url = window.URL.createObjectURL(blob);
      fileSaver.saveAs(blob, 'SurchargeByType.xlsx');
    }), (error: any) => 
      () => this.notification.open('File downloaded successfully');
  }
}
