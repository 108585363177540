import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HTTP_INTERCEPTORS } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of, throwError } from "rxjs";
import { LoadingService } from "../services/loader.service";
import { finalize, catchError } from 'rxjs/operators';
import { Router } from "@angular/router";
import { TokenStorageService } from "../services/token-storage.service";
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class NetworkInterceptor implements HttpInterceptor {
  constructor(private loader: LoadingService, private notification: MatSnackBar, private router: Router, private tokenStorage: TokenStorageService) {}

  intercept(
request: HttpRequest<unknown>,
next: HttpHandler
): Observable<HttpEvent<any>> {
    this.loader.show();
    return next.handle(request).pipe(
      catchError((error) => {
        let handled: boolean = false;
        console.error(error);
        if (error instanceof HttpErrorResponse) {
          if (error.error instanceof ErrorEvent) {
            console.error("Error Event");
          } else {
            console.log(`error status : ${error.status} ${error.statusText}`);
            switch (error.status) {
              case 401:      //login
                this.tokenStorage.signOut();
                this.router.navigateByUrl("/login");
                this.notification.open('Your session has timed out.  Please log back in.');
                console.log(`redirect to login`);
                handled = true;
                break;
              case 403:     //forbidden
                this.router.navigateByUrl("/login");
                console.log(`redirect to login`);
                handled = true;
                break;
            }
          }
        }
        else {
          console.error("Other Errors");
        }
 
        if (handled) {
          console.log('return back ');
          return of(error);
        } else {
          console.log('throw error back to to the subscriber');
          return throwError(error);
        }
      }), 
      finalize(() => {
        this.loader.hide();
      })
    );
  }
}
export const NetworkInterceptorProviders = [
    { provide: HTTP_INTERCEPTORS, useClass: NetworkInterceptor, multi: true }
  ];