import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Airbill, AirbillRequest } from '../helpers/interfaces';
import { ApiService } from '../services/api.service.service';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

@Component({
  selector: 'app-airbill-details',
  templateUrl: './airbill-details.component.html',
  styleUrls: ['./airbill-details.component.css']
})

export class AirbillDetailsComponent implements OnInit {
  airbillId: number | undefined;
  constructor(private apiService: ApiService, private route: ActivatedRoute) { }
  airbill!: Airbill;
  airbillRequest!: AirbillRequest;
  
  @ViewChild('packageInfo')
  htmlData!: ElementRef;
  
  ngOnInit(): void {
    const routeParams = this.route.snapshot.paramMap;
    this.airbillId = Number(routeParams.get('airbillId'));
    this.airbillRequest = {
      AirbillId: this.airbillId
    }
    this.apiService.makeStandardApiCall('values/GetAirbill', this.airbillRequest).subscribe(
      (data: any) => {
        if (data.StatusCode != 401) {
          this.airbill = JSON.parse(data.Value);
        }
      }
    );
  }

  public openPDF():void {
    const DATA = document.getElementById('packageInfo') as HTMLElement;
      
    html2canvas(DATA).then(canvas => {
        
        let fileWidth = 208;
        let fileHeight = canvas.height * fileWidth / canvas.width;
        
        const FILEURI = canvas.toDataURL('image/png')
        let PDF = new jsPDF('p', 'mm', 'a4');
        let position = 0;
        PDF.addImage(FILEURI, 'PNG', 0, position, fileWidth, fileHeight)
        
        PDF.save('TrackingDetail_' + this.airbill?.AirbillNumber + '.pdf');
    });     
  }
}
