<div class="row" style="padding-bottom: 30px;">
    <table mat-table [dataSource]="clientInvoices" matSort style="width: 90%;" class="mat-elevation-z8 client-invoice-table">
    <!-- Position Column -->
    <ng-container matColumnDef="AccountNumber">  
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Account Number </th>
      <td mat-cell *matCellDef="let element"> {{element.AccountNumber}} </td>
    </ng-container>
  
    <ng-container matColumnDef="InvoiceNumber">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Invoice Number </th>
        <td mat-cell *matCellDef="let element"> {{element.InvoiceNumber}} </td>
      </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="InvoiceDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Invoice Date</th>
      <td mat-cell *matCellDef="let element"> {{element.InvoiceDate | date}} </td>
    </ng-container>
  
    <!-- Percentage Column -->
    <ng-container matColumnDef="InvoiceAmount">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Invoice Total</th>
      <td mat-cell *matCellDef="let element"> {{element.InvoiceAmount | currency}} </td>
    </ng-container>
  
      <ng-container matColumnDef="invoice-action">
        <th mat-header-cell *matHeaderCellDef>Action</th>
        <td mat-cell *matCellDef="let element"> <button mat-button style="width:100%;text-align: left;" (click)="downloadInvoice(element.InvoiceID, element.ReportName)"><mat-icon>file_download</mat-icon></button> </td>
      </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <mat-paginator #paginator [pageSizeOptions]="[5, 10, 20]" style="width: 90%;" showFirstLastButtons></mat-paginator>
</div>