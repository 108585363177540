import {AppCommonFunctions} from './app-common-functions';

export const appConstants = {
	fun: AppCommonFunctions,
	frequencies: [ // TODO DELETE
		{value: '1', viewValue: 'Week'},
		{value: '2', viewValue: 'Month'},
		{value: '3', viewValue: 'Year'}
	],
	carriers: [
		{value: '0', viewValue: 'All'},
		{value: '1', viewValue: 'FEDEX'},
		{value: '2', viewValue: 'UPS'}
	],
	carriers_limited: [
		{value: '1', viewValue: 'FEDEX'},
		{value: '2', viewValue: 'UPS'}
	],
	Carriers: {
		FEDEX: 1,
		UPS: 2,
	},
	OPTIONS: {
		CHARTS: {
			DEFAULT: {
				LINE_CHART_DEFAULT_COLOR: {
					backgroundColor: 'rgba(255,255,255,0)',
					borderColor: 'rgba(255,100,133,1)',
					pointBackgroundColor: 'rgba(148,159,177,1)',
					pointBorderColor: '#fff',
					pointHoverBackgroundColor: '#fff',
					pointHoverBorderColor: 'rgba(148,159,177,0.8)'
				},
				Bar_CHART_DEFAULT_COLOR: {
					backgroundColor: 'rgba(54, 163, 247,0)',
					borderColor: 'rgba(54, 163, 247,0)',

				},
			},
		},
	},
	BarOPTIONS: {
		CHARTS: {
			DEFAULT: {
				LINE_CHART_DEFAULT_COLOR: {
					backgroundColor: 'rgba(100,200,255,0)',
					borderColor: 'rgba(130,103,230,1)',
					pointBackgroundColor: 'rgba(148,159,177,1)',
					pointBorderColor: '#fff',
					pointHoverBackgroundColor: '#fff',
					pointHoverBorderColor: 'rgba(148,159,177,0.8)'
				},
			},
		},
	},
	CHARTS: {
		TYPES: {
			HORIZONTAL_BAR: 'horizontalBar',
			VERTICAL_BAR: 'bar',
            LINE_CHART: 'line',
            PIE_CHART: 'pie'
		}
	},
	FREQUENCIES: {
		WEEK: '1',
		MONTH: '2',
		YEAR: '3'
	},
	CARRIERS: {
		ALL: '0',
		FEDEX: '1',
		UPS: '2'
	},
	USER_TYPE: {
		ADMIN: 'ADMIN',
		USER: 'USER',
	},
	CONFIG: {
		DATA_TABLE: {
			PAGE_LIMIT: 50
		}
	},
};