import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  constructor(private authService: AuthService, private notification: MatSnackBar, private router: Router) { }

  attemptingLogin: boolean = false;

  form: any = {
    username: null
  };

  ngOnInit(): void {
  }

  onSubmit(): void {
    const { username } = this.form;
    this.attemptingLogin = true;
    this.authService.forgotpassword(this.form.username).subscribe(
      data => {
          if (data.StatusCode == 500 ){
            this.notification.open("An error occurred, please try again later.");
          } else {this.notification.open("An error occurred, please try again later.");
            this.notification.open("If there is a valid email on file please check it for the link to reset.");
            this.router.navigate(['/']);
        }
        this.attemptingLogin = false;
      },
      err => {
        this.notification.open("An error occurred, please try again later.");
        this.attemptingLogin = false;
      }
    );
  }
}
