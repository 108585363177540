import { Component, OnInit } from '@angular/core';
import { UserInfo } from '../helpers/interfaces';
import { ApiService } from '../services/api.service.service';
import { UserService } from '../services/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent implements OnInit {
  form: UserInfo = {
    Address: '',
    City: '',
    ClientId: 0,
    CompanyName: '',
    Email: '',
    FirstName: '',
    LastName: '',
    Occupation: '',
    ResetToken: '',
    State: '',
    TokenAdded: '',
    UserName: '',
    Zip: '',
    Password: '',
    UserType: ''
  };

  constructor(private userService: UserService, private apiService: ApiService, private notification: MatSnackBar ) { }

  ngOnInit(): void {
    this.form = this.userService.userInfo;
  }

  onSubmit(): void {
    this.apiService.updateUser(this.form).subscribe(
      data => {
          if (data.StatusCode == 401 ){
             
          } else {
            this.userService.saveUser(this.form);
            this.notification.open('Profile Updated!', 'Dismiss', {
              duration: 3000
            });
        }
        
      },
      
    );;
  }
}
