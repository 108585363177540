import { Injectable } from '@angular/core';
import { ReportApiRequest, SearchApiRequest, SearchField, StandardApiRequest } from '../helpers/interfaces';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  constructor() { }
  dateToday: Date = new Date();

  reportForm: ReportApiRequest = {
    StartDate: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000),
    EndDate: this.dateToday,
    CarrierId: 0,
    AccountId: 0,
    ClientId: 0,
    IsPageLoad: false,
    ReportId: 0
  }

  dashboardStatForm: StandardApiRequest = {
    StartDate: new Date(new Date(Date.now() - 14 * 24 * 60 * 60 * 1000)),
    EndDate: new Date(this.dateToday),
    CarrierId: 0,
    AccountId: 0,
    ClientId: 0,
    IsPageLoad: false
  }

  avoidableCostForm: StandardApiRequest = {
    StartDate: new Date(this.dateToday.getFullYear(), 0, 1),
    EndDate: new Date(this.dateToday),
    CarrierId: 0,
    AccountId: 0,
    ClientId: 0,
    IsPageLoad: false
  }

  performanceForm: StandardApiRequest = {
    StartDate: new Date(this.dateToday.getFullYear(), 1, 1),
    EndDate: new Date(this.dateToday),
    CarrierId: 0,
    AccountId: 0,
    ClientId: 0,
    IsPageLoad: false
  }

  trendsForm: StandardApiRequest = {
    StartDate: new Date(this.dateToday.getFullYear(), 1, 1),
    EndDate: new Date(this.dateToday),
    CarrierId: 0,
    AccountId: 0,
    ClientId: 0,
    IsPageLoad: false
  }

  searchForm: SearchApiRequest = {
    StartDate: new Date(new Date(Date.now() - 365 * 24 * 60 * 60 * 1000)),
    EndDate: new Date(this.dateToday),
    CarrierId: 0,
    AccountId: 0,
    ClientId: 0,
    SearchFieldId: 0,
    SearchValue: ''
  }

  SearchFields: SearchField[] = []
}
