<div class="row" style="margin: 5px;">
    <button style="position: relative;right:0;" fxFlex="10%" fxFlex.xs="25%" fxFlex.md="20%" mat-raised-button (click)="openPDF()">Download PDF</button>
</div>
<mat-card id="packageInfo"> 
    <mat-card-title >Package Information</mat-card-title>
    
    <div class="row" style="margin-top: 5px;">
        <mat-form-field class="form-group" style="padding-right: 50px;" >
            <mat-label for="AirbillId">Airbill Id</mat-label>
            <input *ngIf="airbill" disabled matInput type="text" class="form-control" name="AirbillId" [(ngModel)]="airbill.AirbillID"
                #AirbillId="ngModel" />
        </mat-form-field>
    
        <mat-form-field class="form-group">
            <mat-label for="TrackingNumber">Tracking Number</mat-label>
            <input matInput *ngIf="airbill" type="text" class="form-control" name="TrackingNumber" [(ngModel)]="airbill.AirbillNumber"
                disabled #TrackingNumber="ngModel" />
        </mat-form-field>
</div>
    <div class="row">
            <mat-form-field class="form-group" style="padding-right: 50px;" >
                <mat-label for="AccountNumber">Account Number</mat-label>
                <input *ngIf="airbill" disabled matInput type="text" class="form-control" name="AccountNumber" [(ngModel)]="airbill.AccountNumber"
                    #AccountNumber="ngModel" />
            </mat-form-field>
        
            <mat-form-field class="form-group">
                <mat-label for="InvoiceNumber">Invoice Number</mat-label>
                <input matInput *ngIf="airbill" type="text" class="form-control" name="InvoiceNumber" [(ngModel)]="airbill.InvoiceNumber"
                    disabled #InvoiceNumber="ngModel" />
            </mat-form-field>
    </div>
    <div class="row">
        <mat-form-field class="form-group" style="padding-right: 50px;" >
            <mat-label for="CarrierServiceName">Service Name</mat-label>
            <input *ngIf="airbill" disabled matInput type="text" class="form-control" name="CarrierServiceName" [(ngModel)]="airbill.CarrierServiceName"
                #CarrierServiceName="ngModel" />
        </mat-form-field>
    
        <mat-form-field class="form-group">
            <mat-label for="ReferenceNumber">Reference Number</mat-label>
            <input matInput *ngIf="airbill" type="text" class="form-control" name="ReferenceNumber" [(ngModel)]="airbill.ReferenceNumber"
                disabled #ReferenceNumber="ngModel" />
        </mat-form-field>
</div>
<div class="row">
    <mat-form-field class="form-group" style="padding-right: 50px;" >
        <mat-label for="SignedBy">Signed By</mat-label>
        <input *ngIf="airbill" disabled matInput type="text" class="form-control" name="SignedBy" [(ngModel)]="airbill.SignedBy"    
            #SignedBy="ngModel" />
    </mat-form-field>

    <mat-form-field class="form-group">
        <mat-label for="DeliveryDate">Delivery Date</mat-label>
        <input matInput *ngIf="airbill" type="text" class="form-control" name="DeliveryDate" [(ngModel)]="airbill.DeliveryDate"
            disabled #DeliveryDate="ngModel" />
    </mat-form-field>
</div>
<div class="row">
    <mat-form-field class="form-group" style="padding-right: 50px;" >
        <mat-label for="PickupDate">Pickup Date</mat-label>
        <input *ngIf="airbill" disabled matInput type="text" class="form-control" name="PickupDate" [(ngModel)]="airbill.PickupDate"
            #PickupDate="ngModel" />
    </mat-form-field>

    <mat-form-field class="form-group">
        <mat-label for="Deadline">Deadline Date</mat-label>
        <input matInput *ngIf="airbill" type="text" class="form-control" name="Deadline" [(ngModel)]="airbill.Deadline"
            disabled #Deadline="ngModel" />
    </mat-form-field>
</div>
<div class="row">
    <mat-form-field class="form-group" style="padding-right: 50px;" >
        <mat-label for="Weight">Actual Weight</mat-label>
        <input *ngIf="airbill" disabled matInput type="text" class="form-control" name="Weight" [(ngModel)]="airbill.Weight"
            #Weight="ngModel" />
    </mat-form-field>

    <mat-form-field class="form-group">
        <mat-label for="RatedWeight">Billed Weight</mat-label>
        <input matInput *ngIf="airbill" type="text" class="form-control" name="RatedWeight" [(ngModel)]="airbill.RatedWeight"
            disabled #RatedWeight="ngModel" />
    </mat-form-field>
</div>
<div class="row">
    <mat-form-field class="form-group" style="padding-right: 50px;" >
        <mat-label for="Zone">Zone</mat-label>
        <input *ngIf="airbill" disabled matInput type="text" class="form-control" name="Zone" [(ngModel)]="airbill.Zone"
            #Zone="ngModel" />
    </mat-form-field>

    <mat-form-field class="form-group">
        <mat-label for="TotalCharge">Total Charge</mat-label>
        <input matInput *ngIf="airbill" type="text" class="form-control" name="TotalCharge" [(ngModel)]="airbill.TotalCharge"
            disabled #TotalCharge="ngModel" />
    </mat-form-field>
</div>
</mat-card>