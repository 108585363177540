import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { ChartOptions } from 'chart.js';
import * as fileSaver from 'file-saver';
import { appConstants } from '../helpers/constants';
import { StandardApiRequest, Carrier, Account, RefundTotalByDisputeType, PerformanceByService, TrackingException, UserInfo, SurchargeTotal } from '../helpers/interfaces';
import { ApiService } from '../services/api.service.service';
import { SessionService } from '../services/session.service';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-refunds-total-chart',
  templateUrl: './refunds-total-chart.component.html',
  styleUrls: ['./refunds-total-chart.component.css']
})
export class RefundsTotalChartComponent implements OnInit {

  constructor(private apiService: ApiService,  private notification: MatSnackBar, private sessionService: SessionService, private userService: UserService) { }

  displayedColumns: string[] = ['month', 'year'];
  dataTable: MatTableDataSource<RefundTotalByDisputeType> = new MatTableDataSource();
  
  userInfo!: UserInfo;
  dateToday: Date = new Date();
  form: StandardApiRequest = {
    StartDate: new Date(this.dateToday.getFullYear(), 1, 1),
    EndDate: new Date(this.dateToday),
    CarrierId: 0,
    AccountId: 0,
    ClientId: 0,
    IsPageLoad: false
  }

  defaultScale = appConstants.fun.getChartScale('Month', 'Amount in Dollars');

  // Refund total chart variables
  refundTotalChartLabels: Array<any> = [];
  refundTotalChartData: number[] = [];
  refundTotalChartType: any = appConstants.CHARTS.TYPES.PIE_CHART;
  refundTotalChartOptions: ChartOptions = {
    responsive: true,

  };
  refundTotalChartColors: Array<any> = [ // TODO get colors from common area
    { backgroundColor: ['rgb(255, 161, 181)', 'rgb(134, 199, 243)', 'rgb(255, 226, 154)', 'rgb(147, 217, 217)', 'rgb(147, 145, 118)', 'rgb(107, 51, 34)', 'rgb(145, 150, 43)', 'rgb(27, 67, 71)', 'rgb(38, 38, 37)', 'rgb(94, 201, 174)'] }
  ];
  refundTotalLoading: boolean = true;

  Carriers: Carrier[] = [{ CarrierId: 0, CarrierName: "All" }]
  Accounts: Account[] = [{ AccountId: 0, AccountNumber: "All" }]

  ngOnInit(): void {
    this.userInfo = this.userService.userInfo;
    this.sessionService.performanceForm.ClientId = this.userInfo.ClientId;
    this.form = this.sessionService.performanceForm;
    
    this.apiService.makeStandardApiCall('user/GetCarriersByClient', this.form).subscribe(
      (data: any) => {
        if (data.StatusCode != 401) {
          const carriers: Carrier[] = JSON.parse(data.Value);
          this.Carriers = [];
          this.Carriers.push({ CarrierName: 'All', CarrierId: 0 });
          carriers.forEach(element => {
            this.Carriers.push(element);
          });
        }
      }
    );
    this.updateDashboardComponents();
    this.updateAccountsList();
  }

  updateAccountsList(): void {
    this.apiService.makeStandardApiCall('user/GetAccountsByClientCarrier', this.form).subscribe(
      (data: any) => {
        if (data.StatusCode != 401) {
          const accounts: Account[] = JSON.parse(data.Value);
          this.Accounts = [];
          this.Accounts.push({ AccountNumber: 'All', AccountId: 0 });
          accounts.forEach(element => {
            this.Accounts.push({ AccountNumber: element.AccountNumber.trim().toUpperCase(), AccountId: element.AccountId });
          });
        }
      }
    );
  }

  updateDashboardComponents(): void {
    this.sessionService.performanceForm = this.form;
    
    this.refundTotalLoading = true;
    this.apiService.makeStandardApiCall('values/GetRefundTotal', this.form).subscribe(
      (data: any) => {
        // Temp variables
        const labelsTemp: Array<string> = [];
        const valuesTemp: Array<number> = [];
        const returnData: Array<RefundTotalByDisputeType> = JSON.parse(data.Value);

        this.dataTable = new MatTableDataSource<RefundTotalByDisputeType>(JSON.parse(data.Value));
        // Populate
        returnData.forEach(element => {
          if ((this.form.CarrierId === appConstants.Carriers.FEDEX) && (element.CarrierId !== appConstants.Carriers.FEDEX)) {
            return;
          }
          if ((this.form.CarrierId === appConstants.Carriers.UPS) && (element.CarrierId !== appConstants.Carriers.UPS)) {
            return;
          }
          labelsTemp.push(element.DisputeType);
          valuesTemp.push(element.RefundTotal);
        });

        // Set values to chart
        this.refundTotalChartLabels = labelsTemp;
        this.refundTotalChartData = valuesTemp;

        this.refundTotalLoading = false;
      }
    );

  }

  downloadSearch(): void {
    this.apiService.downloadFile('GetRefundTotalByDisputeDownload', this.form).subscribe((response: any) => { //when you use stricter type checking
      let blob: any = new Blob([response], { type: 'application/octet-stream' });
      const url = window.URL.createObjectURL(blob);
      fileSaver.saveAs(blob, 'RefundsByType.xlsx');
    }), (error: any) => 
      () => this.notification.open('File downloaded successfully');
  }
}
