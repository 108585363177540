import { Component } from '@angular/core';
import { LoadingService } from './services/loader.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Lamprey Aquarium Dashboard';
  loading$ = this.loader.loading$;
  constructor(public loader: LoadingService) {}
}
