import { ChartDataModel } from "./interfaces";

export class AppCommonFunctions {
    static getChartScale(xName: string = '', yName: string = '', beginAtZero = true): object {
		return {
			yAxes: [{
				ticks: {
					beginAtZero: beginAtZero
				},
				scaleLabel: {
					display: yName ? true : false,
					labelString: yName
				}
			}],
			xAxes: [{
				scaleLabel: {
					display: xName ? true : false,
					labelString: xName
				}
			}]
		};
	}
    static parseChartDataFromApi(res: any[], column: string): ChartDataModel {
		const obj = new ChartDataModel();
		res.forEach(x => {
			obj.labels.push(x.Month.substring(0, 3) + '-' + x.Year.substring(2));
			obj.values.push(x[column]);
		});
		return obj;
	}
}
