import { Component, OnInit } from '@angular/core';
import { Account, Carrier, Report, ReportApiRequest, StandardApiRequest, UserInfo } from '../helpers/interfaces';
import { ApiService } from '../services/api.service.service';
import { UserService } from '../services/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as fileSaver from 'file-saver';
import { SessionService } from '../services/session.service';

@Component({
  selector: 'app-report-download',
  templateUrl: './report-download.component.html',
  styleUrls: ['./report-download.component.css']
})

export class ReportDownloadComponent implements OnInit {

  constructor(private apiService: ApiService, private notification: MatSnackBar, private sessionService: SessionService, private userService: UserService) { }
  dateToday: Date = new Date();
  form: ReportApiRequest = {
    StartDate: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000),
    EndDate: this.dateToday,
    CarrierId: 0,
    AccountId: 0,
    ClientId: 0,
    IsPageLoad: false,
    ReportId: 0
  }

  formErrors: boolean = false;
  errorMessage: string = '';
  fileDownloading: boolean = false;

  userInfo!: UserInfo;
  Carriers: Carrier[] = [{ CarrierId: 0, CarrierName: "All" }]
  Accounts: Account[] = [{ AccountId: 0, AccountNumber: "All" }]
  Reports: Report[] = []

  ngOnInit(): void {
    this.userInfo = this.userService.userInfo;
    this.sessionService.reportForm.ClientId = this.userInfo.ClientId;
    this.form = this.sessionService.reportForm;

    this.apiService.makeStandardApiCall('user/GetCarriersByClient', this.form).subscribe(
      (data: any) => {
        if (data.StatusCode != 401) {
          const carriers: Carrier[] = JSON.parse(data.Value);
          this.Carriers = [];
          this.Carriers.push({ CarrierName: 'All', CarrierId: 0 });
          carriers.forEach(element => {
            this.Carriers.push(element);
          });
        }
      }
    );
    this.updateAccountsList();
    this.updateReportList();
  }

  updateAccountsList(): void {
    this.apiService.makeStandardApiCall('user/GetAccountsByClientCarrier', this.form).subscribe(
      (data: any) => {
        if (data.StatusCode != 401) {
          const accounts: Account[] = JSON.parse(data.Value);
          this.Accounts = [];
          this.Accounts.push({ AccountNumber: 'All', AccountId: 0 });
          accounts.forEach(element => {
            this.Accounts.push({ AccountNumber: element.AccountNumber.trim().toUpperCase(), AccountId: element.AccountId });
          });
        }
      }
    );
  }

  updateReportList(): void {
    this.apiService.makeStandardApiCall('values/GetReportsForDownload', this.form).subscribe(
      (data: any) => {
        if (data.StatusCode != 401) {
          const reports: Report[] = JSON.parse(data.Value);
          this.Reports = [];
          reports.forEach(element => {
            this.Reports.push({ ReportId: element.ReportId, ReportName: element.ReportName });
          });
        }
      }
    );
  }

  downloadReport(): void {
    this.formErrors = false;
    this.errorMessage = '';
    if (this.form.ReportId === 0) {
      this.errorMessage = 'Report must be selected.\n';
      this.formErrors = true;
    }
    // switch (this.form.ReportId) {
    //   case 10:
    //     if (this.form.CarrierId === 0){
    //       this.errorMessage += 'Carrier must be selected.';
    //       this.formErrors = true;
    //     }
    //     break;
    // }
    if (!this.formErrors) {
      this.sessionService.reportForm = this.form;
      this.errorMessage = '';
      this.formErrors = false;
      this.fileDownloading = true;
      this.apiService.downloadReportFile(this.form).subscribe((response: any) => { //when you use stricter type checking
        let blob: any = new Blob([response], { type: 'application/octet-stream' });
        const url = window.URL.createObjectURL(blob);
        let reportName = '';
        this.Reports.forEach(element => {
          if (element.ReportId === this.form.ReportId)
            reportName = element.ReportName;
        });
        switch (this.form.ReportId) {
          case 10:
          case 11:
          case 12:
          case 13:
            fileSaver.saveAs(blob, reportName + '.xls');
            break;
          default:
            fileSaver.saveAs(blob, reportName + '.xlsx');
            break;
        }
        this.fileDownloading = false;
      }, (error: any) => {
        this.fileDownloading = false;
        console.log('Error downloading the file');
      }, //when you use stricter type checking
        () => console.info('File downloaded successfully'));
    }

  }
}
