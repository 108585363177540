import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MatSliderModule } from '@angular/material/slider';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonModule } from '@angular/material/button';
import { MatRadioModule } from '@angular/material/radio';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DashboardStatsComponent } from './dashboard-stats/dashboard-stats.component';
import { LoginComponent } from './login/login.component';
import { DashboardMenuComponent } from './dashboard-menu/dashboard-menu.component';
import { DashboardHeaderComponent } from './dashboard-header/dashboard-header.component';
import { DashboardFooterComponent } from './dashboard-footer/dashboard-footer.component';
import { AuthInterceptorProviders } from './helpers/auth.interceptor';
import { NetworkInterceptorProviders } from './helpers/network.interceptor';
import { AvoidableCostComponent } from './avoidable-cost/avoidable-cost.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule} from '@angular/material/snack-bar';
import { ChartsModule } from 'ng2-charts';
import { GoogleMapsModule } from '@angular/google-maps'
import { Ng2LoadingSpinnerModule } from 'ng2-loading-spinner'
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReportDownloadComponent } from './report-download/report-download.component';
import { TrendsComponent } from './trends/trends.component';
import { MonthlyInvoicesComponent } from './monthly-invoices/monthly-invoices.component';
import { PerformanceComponent } from './performance/performance.component';
import { PackageSearchComponent } from './package-search/package-search.component';
import { CarrierInvoicesComponent } from './carrier-invoices/carrier-invoices.component';
import { AirbillDetailsComponent } from './airbill-details/airbill-details.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NetTransChartComponent } from './net-trans-chart/net-trans-chart.component';
import { SurchargeTypeChartComponent } from './surcharge-type-chart/surcharge-type-chart.component';
import { CountWeightChartComponent } from './count-weight-chart/count-weight-chart.component';
import { PackagesWeightChartComponent } from './packages-weight-chart/packages-weight-chart.component';
import { CostPoundChartComponent } from './cost-pound-chart/cost-pound-chart.component';
import { CostPackageChartComponent } from './cost-package-chart/cost-package-chart.component';
import { SurchargeMonthChartComponent } from './surcharge-month-chart/surcharge-month-chart.component';
import { TotalFreightMonthChartComponent } from './total-freight-month-chart/total-freight-month-chart.component';
import { TotalWeightMonthChartComponent } from './total-weight-month-chart/total-weight-month-chart.component';
import { TotalNetMonthChartComponent } from './total-net-month-chart/total-net-month-chart.component';
import { RefundsByMonthChartComponent } from './refunds-by-month-chart/refunds-by-month-chart.component';
import { RefundsTotalChartComponent } from './refunds-total-chart/refunds-total-chart.component';
import { PerformanceByServiceChartComponent } from './performance-by-service-chart/performance-by-service-chart.component';
import { TrackingExceptionsChartComponent } from './tracking-exceptions-chart/tracking-exceptions-chart.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { AddClientComponent } from './add-client/add-client.component';

@NgModule({
  declarations: [
    AppComponent,
    DashboardStatsComponent,
    LoginComponent,
    DashboardMenuComponent,
    DashboardHeaderComponent,
    DashboardFooterComponent,
    AvoidableCostComponent,
    UserProfileComponent,
    ReportDownloadComponent,
    TrendsComponent,
    MonthlyInvoicesComponent,
    PerformanceComponent,
    PackageSearchComponent,
    CarrierInvoicesComponent,
    AirbillDetailsComponent,
    NetTransChartComponent,
    SurchargeTypeChartComponent,
    CountWeightChartComponent,
    PackagesWeightChartComponent,
    CostPoundChartComponent,
    CostPackageChartComponent,
    SurchargeMonthChartComponent,
    TotalFreightMonthChartComponent,
    TotalWeightMonthChartComponent,
    TotalNetMonthChartComponent,
    RefundsByMonthChartComponent,
    RefundsTotalChartComponent,
    PerformanceByServiceChartComponent,
    TrackingExceptionsChartComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    AddClientComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    MatSliderModule,
    MatInputModule,
    MatToolbarModule,
    MatTooltipModule,
    MatSidenavModule,
    MatButtonModule,
    MatIconModule,
    MatRadioModule,
    MatDividerModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatCardModule,
    MatSnackBarModule,
    ChartsModule,
    GoogleMapsModule,
    FlexLayoutModule,
    MatPaginatorModule,
    MatSortModule,
    MatFormFieldModule,
    Ng2LoadingSpinnerModule.forRoot({})
  ],
  providers: [AuthInterceptorProviders, NetworkInterceptorProviders, {provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: {duration: 2500, location: top}}, {provide: LocationStrategy, useClass: HashLocationStrategy}],
  bootstrap: [AppComponent]
})
export class AppModule { }
