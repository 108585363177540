<div class="row">
    <mat-card style="margin: 20px;">
        <mat-card-title>FILTERS</mat-card-title>
        <mat-form-field style="margin: 5px;" appearance="fill">
            <mat-label>Start Date</mat-label>
            <input matInput [matDatepicker]="startdate" [(ngModel)]="form.StartDate">
            <mat-datepicker-toggle matSuffix [for]="startdate"></mat-datepicker-toggle>
            <mat-datepicker #startdate></mat-datepicker>
        </mat-form-field>
        <mat-form-field style="margin: 5px;" appearance="fill">
            <mat-label>End Date</mat-label>
            <input matInput [matDatepicker]="enddate" [(ngModel)]="form.EndDate">
            <mat-datepicker-toggle matSuffix [for]="enddate"></mat-datepicker-toggle>
            <mat-datepicker #enddate></mat-datepicker>
        </mat-form-field>
        <mat-form-field style="margin: 5px;" appearance="fill">
            <mat-label>Carrier</mat-label>
            <mat-select [(ngModel)]="form.CarrierId" name="carrier" (change)="updateAccountsList()">
                <mat-option *ngFor="let car of Carriers" [value]="car.CarrierId">
                    {{car.CarrierName}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field style="margin: 5px;" appearance="fill">
            <mat-label>Account</mat-label>
            <mat-select [(ngModel)]="form.AccountId" name="account">
                <mat-option *ngFor="let acc of Accounts" [value]="acc.AccountId">
                    {{acc.AccountNumber}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <div style="display: inline-block;position: relative;text-align:left;margin-left:20px;">
            <button mat-raised-button (click)="updateDashboardComponents();">
                Update
            </button>
        </div>
    </mat-card>
    <div fxLayout="row wrap" [ng2-loading]="statsLoading">
        <div fxFlex="25%" fxFlex.xs="100%" fxFlex.sm="100%" fxFlex.md="50%">
            <mat-card class="dashboard-card">
                <div fxLayout="row" class="mat-sub-div">
                    <mat-card-subtitle>Total Net Charge</mat-card-subtitle>
                </div>
                <div fxLayout="row">
                    <h2>
                        <mat-label>{{dashboardStats.NetSpend_Amount | currency}}</mat-label>
                    </h2>
                </div>
                <div fxLayout="row" class="mat-sub-div">
                    <mat-card-subtitle>Packages</mat-card-subtitle>
                </div>
                <div fxLayout="row">
                    <h2>
                        <mat-label>{{dashboardStats.NetSpend_Count | number:'1.0':'en-US' }}</mat-label>
                    </h2>
                </div>
            </mat-card>
        </div>
        <div fxFlex="25%" fxFlex.xs="100%" fxFlex.sm="100%" fxFlex.md="50%">
            <mat-card class="dashboard-card">
                <div fxLayout="row" class="mat-sub-div">
                    <mat-card-subtitle>Total Surcharges</mat-card-subtitle>
                </div>
                <div fxLayout="row">
                    <h2>
                        <mat-label>{{dashboardStats.Surcharges_Amount | currency}}</mat-label>
                    </h2>
                </div>
                <div fxLayout="row" class="mat-sub-div">
                    <mat-card-subtitle>Surcharges</mat-card-subtitle>
                </div>
                <div fxLayout="row">
                    <h2>
                        <mat-label>{{dashboardStats.Surcharges_Count | number:'1.0':'en-US' }}</mat-label>
                    </h2>
                </div>
            </mat-card>
        </div>
        <div fxFlex="25%" fxFlex.xs="100%" fxFlex.sm="100%" fxFlex.md="50%">
            <mat-card class="dashboard-card">
                <div fxLayout="row" class="mat-sub-div">
                    <mat-card-subtitle>Transportation Charge</mat-card-subtitle>
                </div>
                <div fxLayout="row">
                    <h2>
                        <mat-label>{{dashboardStats.FreightSpend_Amount | currency}}</mat-label>
                    </h2>
                </div>
                <div fxLayout="row" class="mat-sub-div">
                    <mat-card-subtitle>Effective Discount</mat-card-subtitle>
                </div>
                <div fxLayout="row">
                    <h2>
                        <mat-label>UPS: {{dashboardStats.FreightSpend_UPSEffectiveDiscount}}% Fedex:
                            {{dashboardStats.FreightSpend_FedexEffectiveDiscount}}%</mat-label>
                    </h2>
                </div>
            </mat-card>
        </div>
        <div fxFlex="25%" fxFlex.xs="100%" fxFlex.sm="100%" fxFlex.md="50%">
            <mat-card class="dashboard-card">
                <div fxLayout="row" class="mat-sub-div">
                    <mat-card-subtitle>Avg Zone</mat-card-subtitle>
                </div>
                <div fxLayout="row">
                    <h2>
                        <mat-label>{{dashboardStats.AverageZone_Amount | number : '1.2-2'}}</mat-label>
                    </h2>
                </div>
                <div fxLayout="row" class="mat-sub-div">
                    <mat-card-subtitle>Average Cost Per Package</mat-card-subtitle>
                </div>
                <div fxLayout="row">
                    <h2>
                        <mat-label>{{dashboardStats.WeightCostPerPackage | currency}}</mat-label>
                    </h2>
                </div>
            </mat-card>
        </div>

    </div>
    <div fxLayout="row wrap">
        <div fxFlex="50%" fxFlex.md="100%" fxFlex.sm="100%" fxFlex.xs="100%">
            <mat-card class="dashboard-card">
                <mat-card-title>
                    NET TRANSPORTATION BY SERVICE
                    
                    <mat-icon (click)="goToLink('nettransbyservice')" style="float: right;">bar_chart</mat-icon>
                </mat-card-title>
                <div class="chart-wrapper" [ng2-loading]="netChargeLoading">
                    <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
                    [(ngModel)]="transLabels" (change)="toggleTransType($event)" style="margin-left:30%;">
                    <mat-radio-button style="margin:10px;" class="example-radio-button" *ngFor="let tT of transTypes"
                        [value]="tT">
                        {{tT}}
                    </mat-radio-button>
                </mat-radio-group>
                    <canvas baseChart [datasets]="netChargeChartData" [labels]="netChargeLabels"
                        [options]="netChargeOptions" [legend]="netChargeLegend" [chartType]="netChargeType"
                        [colors]="netChargeColors">
                    </canvas>
                </div>

            </mat-card>
        </div>
        <div fxFlex="50%" fxFlex.md="100%"  fxFlex.sm="100%" fxFlex.xs="100%">
            <mat-card class="dashboard-card">

                <mat-card-title>
                    SURCHARGES BY TYPE
                    <mat-icon (click)="goToLink('surchargebytype')" style="float: right;">bar_chart</mat-icon>
                </mat-card-title>
                <mat-card-content>

                    <div class="chart-wrapper" [ng2-loading]="surchargesLoading">
<div style="height:40px;"></div>
                        <canvas baseChart [data]="surchargeData" [labels]="surchargeLabels" [options]="surchargeOptions"
                            [chartType]="doughnutChartType">
                        </canvas>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
    <div fxLayout="row wrap">
        <div fxFlex="50%" fxFlex.md="100%"  fxFlex.sm="100%" fxFlex.xs="100%">
            <mat-card class="dashboard-card">
                <mat-card-title>
                    PACKAGE COUNT BY WEIGHT GROUP
                    <mat-icon (click)="goToLink('countbyweightgroup')" style="float: right;">bar_chart</mat-icon>
                </mat-card-title>
                <div class="chart-wrapper" [ng2-loading]="packageCountLoading">
                    <canvas baseChart [datasets]="weightChartData" [labels]="weightChartLabels"
                        [options]="weightChartOptions" [legend]="weightChartLegend" [chartType]="weightChartType">
                    </canvas>
                </div>

            </mat-card>
        </div>
        <div fxFlex="50%" fxFlex.md="100%"  fxFlex.sm="100%" fxFlex.xs="100%">
            <mat-card class="dashboard-card">
                <mat-card-title>
                    LAYERED PACKAGES BY ZONE
                    <mat-icon (click)="goToLink('packagesbyzone')" style="float: right;">bar_chart</mat-icon>
                </mat-card-title>
                <div class="chart-wrapper" [ng2-loading]="layeredPackageLoading">
                    <canvas baseChart [datasets]="layeredPackagesByZonebarChartData"
                        [labels]="layeredPackagesByZonebarChartLabels" [options]="layeredPackagesByZonebarChartOptions"
                        [legend]="layeredPackagesByZonebarChartLegend" [chartType]="layeredPackagesByZonebarChartType">
                    </canvas>
                </div>

            </mat-card>
        </div>
    </div>
    <div fxLayout="row wrap">
        <div fxFlex="100%">
            <mat-card class="dashboard-card">
                <mat-card-title>
                    PACKAGES BY STATE
                </mat-card-title>
                <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
                    [(ngModel)]="maplabels" (change)="toggleVisibility($event)" style="margin-left:30%;">
                    <mat-radio-button style="margin:10px;" class="example-radio-button" *ngFor="let season of map"
                        [value]="season">
                        {{season}}
                    </mat-radio-button>
                </mat-radio-group>

                <div class="chart-wrapper" [ng2-loading]="mapLoading">
                    <div fxFlex="100%" #geoMapRef id="geoMapRef"></div>
                </div>

            </mat-card>
        </div>
    </div>
</div>