import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ChartOptions, ChartType } from 'chart.js';
import { appConstants } from '../helpers/constants';
import { Account, Carrier, ChartDataModel, StandardApiRequest, UserInfo } from '../helpers/interfaces';
import { ApiService } from '../services/api.service.service';
import { SessionService } from '../services/session.service';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-trends',
  templateUrl: './trends.component.html',
  styleUrls: ['./trends.component.css']
})

export class TrendsComponent implements OnInit {

  constructor(private apiService: ApiService, private router: Router, private sessionService: SessionService, private userService: UserService) { }

  defaultScale = appConstants.fun.getChartScale('Month', 'Amount in Dollars');
  costPerPoundScale = appConstants.fun.getChartScale('Month', 'Amount in Dollars/lb.')
  totalWeightScale = appConstants.fun.getChartScale('Month', 'Weight in Pounds')

  userInfo!: UserInfo;
  dateToday: Date = new Date();
  form: StandardApiRequest = {
    StartDate: new Date(this.dateToday.getFullYear(), 1, 1),
    EndDate: new Date(this.dateToday),
    CarrierId: 0,
    AccountId: 0,
    ClientId: 0,
    IsPageLoad: false
  }

  Carriers: Carrier[] = [{ CarrierId: 0, CarrierName: "All" }]
  Accounts: Account[] = [{ AccountId: 0, AccountNumber: "All" }]
  netChargeLoading: boolean = true;
  weightLoading: boolean = true;
  freightLoading: boolean = true;
  surchargeLoading: boolean = true;
  costPackageLoading: boolean = true;
  costPoundLoading: boolean = true;

  // [Total NET-CHARGE]
  totalNetChargeChartData: Array<any> = [{ data: [] }];
  totalNetChargeChartLabels: Array<any> = [];
  totalNetChargeChartOptions: any = {
    responsive: true,
    scales: this.defaultScale,
    isLoading: true,
    tooltips: {
      enabled: true,
      mode: 'single',
      callbacks: {
        label: function (tooltipItems: { yLabel: string; }, data: any) {
          return ' $' + tooltipItems.yLabel;
        }
      }
    }
  };
  totalNetChargeChartLegend: boolean = false;
  totalNetChargeChartType: any = appConstants.CHARTS.TYPES.LINE_CHART;
  //totalNetChargeChartColors: Array<any> = [appConstants.OPTIONS.CHARTS.DEFAULT.LINE_CHART_DEFAULT_COLOR];
  public totalNetChargeChartColors: Array<any> = [{}];

  // [Total Weight By Month]
  totalWeightChartOptions: any = {
    responsive: true,
    isLoading: true,
    scales: this.totalWeightScale,
    tooltips: {
      enabled: true,
      mode: 'single',

      callbacks: {
        label: function (tooltipItems: { yLabel: any; }, data: any) {
          return tooltipItems.yLabel;
        }
      }
    }

  };
  totalWeightChartLabels: string[] = [];
  totalWeightChartType: any = appConstants.CHARTS.TYPES.VERTICAL_BAR;
  totalWeightChartLegend: boolean = false;
  totalWeightChartData: Array<any> = [{ data: null }];
  public totalWeightChartColors: Array<any> = [{}];
  //totalWeightChartColors: Array<any> = [appConstants.OPTIONS.CHARTS.DEFAULT.bar_CHART_DEFAULT_COLOR];


  // [TOTAL FRIGHT CHARGE BY MONTH]
  totalFreightChargeChartOptions: any = {
    responsive: true,
    isLoading: true,
    scales: this.defaultScale,
    tooltips: {
      enabled: true,
      mode: 'single',
      callbacks: {
        label: function (tooltipItems: { yLabel: string; }, data: any) {
          return ' $' + tooltipItems.yLabel;
        }
      }
    }
  };
  totalFreightChargeChartLabels: string[] = [];
  totalFreightChargeChartType: any = appConstants.CHARTS.TYPES.VERTICAL_BAR;
  totalFreightChargeChartLegend: boolean = false;
  totalFreightChargeChartData: Array<any> = [{ data: null }];
  public totalFreightChargeChartColors = [{}];
  // [SURCHARGE TOTAL MONTH]
  surchargeTotalChartData: Array<any> = [{ data: [] }];
  surchargeTotalChartLabels: Array<any> = [];
  surchargeTotalChartOptions: any = {
    responsive: true,
    scales: this.defaultScale,
    isLoading: true,
    tooltips: {
      enabled: true,
      mode: 'single',
      callbacks: {
        label: function (tooltipItems: { yLabel: string; }, data: any) {
          return ' $' + tooltipItems.yLabel;
        }
      }
    }
  };
  surchargeTotalChartLegend: boolean = false;
  surchargeTotalChartType: any = appConstants.CHARTS.TYPES.LINE_CHART;
  //surchargeTotalChartColors: Array<any> = [appConstants.OPTIONS.CHARTS.DEFAULT.LINE_CHART_DEFAULT_COLOR];
  public surchargeTotalChartColors: Array<any> = [{}];
  // [COST PER PACKAGE BY MONTH]
  costPerPackageChartData: Array<any> = [{ data: [] }];
  costPerPackageChartLabels: Array<any> = [];
  costPerPackageChartOptions: any = {
    responsive: true,
    scales: this.defaultScale,
    isLoading: true,
    tooltips: {
      enabled: true,
      mode: 'single',
      callbacks: {
        label: function (tooltipItems: { yLabel: string; }, data: any) {
          return ' $' + tooltipItems.yLabel;
        }
      }
    }
  };
  costPerPackageChartLegend: boolean = false;
  costPerPackageChartType: any = appConstants.CHARTS.TYPES.LINE_CHART;
  //costPerPackageChartColors: Array<any> = [appConstants.OPTIONS.CHARTS.DEFAULT.LINE_CHART_DEFAULT_COLOR];
  public costPerPackageChartColors: Array<any> = [{}];
  // [COST PER POUND BY MONTH]
  public costPerPoundChartOptions: any = {
    responsive: true,
    scales: this.costPerPoundScale,
    tooltips: {
      enabled: true,
      mode: 'single',
      callbacks: {
        label: function (tooltipItems: { yLabel: string; }, data: any) {
          return ' $' + tooltipItems.yLabel;
        }
      }
    }
  };
  costPerPoundChartLabels: string[] = [];
  costPerPoundChartType: any = appConstants.CHARTS.TYPES.VERTICAL_BAR;
  costPerPoundChartLegend: boolean = false;
  costPerPoundChartData: any[] = [{ data: null }];
  public costPerPoundChartColors: Array<any> = [{}];

  ngOnInit(): void {
    this.userInfo = this.userService.userInfo;
    this.sessionService.trendsForm.ClientId = this.userInfo.ClientId;
    this.form = this.sessionService.trendsForm;

    this.apiService.makeStandardApiCall('user/GetCarriersByClient', this.form).subscribe(
      (data: any) => {
        if (data.StatusCode != 401) {
          const carriers: Carrier[] = JSON.parse(data.Value);
          this.Carriers = [];
          this.Carriers.push({ CarrierName: 'All', CarrierId: 0 });
          carriers.forEach(element => {
            this.Carriers.push(element);
          });
        }
      }
    );
    this.updateDashboardComponents();
    this.updateAccountsList();
  }

  updateAccountsList(): void {
    this.apiService.makeStandardApiCall('user/GetAccountsByClientCarrier', this.form).subscribe(
      (data: any) => {
        if (data.StatusCode != 401) {
          const accounts: Account[] = JSON.parse(data.Value);
          this.Accounts = [];
          this.Accounts.push({ AccountNumber: 'All', AccountId: 0 });
          accounts.forEach(element => {
            this.Accounts.push({ AccountNumber: element.AccountNumber.trim().toUpperCase(), AccountId: element.AccountId });
          });
        }
      }
    );
  }

  updateDashboardComponents(): void {
    this.sessionService.trendsForm = this.form;
    this.totalNetChargeChartOptions.isLoading = true;
    this.netChargeLoading = true;
    this.apiService.makeStandardApiCall('values/GetTotalNetCharge', this.form).subscribe(
      (data: any) => {
        const chartData = appConstants.fun.parseChartDataFromApi(JSON.parse(data.Value), 'NetCharge');

        // Set values to chart
        this.totalNetChargeChartLabels = chartData.labels;
        this.totalNetChargeChartData[0].data = chartData.values;
        this.totalNetChargeChartOptions.isLoading = false;
        this.totalNetChargeChartOptions.tooltips.callbacks;
        this.totalNetChargeChartColors = [
          {
            backgroundColor: 'rgba(255,255,255,0)',
            borderColor: 'rgba(102,163,255,1)', // Data drive this
            pointBackgroundColor: 'rgba(102,163,255,1)',
            pointBorderColor: 'rgba(102,163,255,0)',
          }
        ];
        this.netChargeLoading = false;
      }
    );

    this.weightLoading = true;
    this.apiService.makeStandardApiCall('values/GetTotalWeightByMonth', this.form).subscribe(
      (data: any) => {
        const chartData = appConstants.fun.parseChartDataFromApi(JSON.parse(data.Value), 'Weight');

        // Set values to chart
        this.totalWeightChartLabels = chartData.labels;
        this.totalWeightChartData = chartData.values;
        this.totalWeightChartOptions.isLoading = false;
        this.totalWeightChartOptions.tooltips.callbacks;
        this.totalWeightChartColors = [
          {
            backgroundColor: 'rgb(102,163,255,1)',
          }
        ];
        this.weightLoading = false;
      }
    );
    this.freightLoading = true;
    this.apiService.makeStandardApiCall('values/GetTotalFreightChargesByMonth', this.form).subscribe(
      (data: any) => {
        const chartData = appConstants.fun.parseChartDataFromApi(JSON.parse(data.Value), 'NetCharge');

        // Set values to chart
        this.totalFreightChargeChartLabels = chartData.labels;
        this.totalFreightChargeChartData = chartData.values;
        this.totalFreightChargeChartOptions.isLoading = false;
        this.totalFreightChargeChartOptions.tooltips.callbacks;
        this.totalFreightChargeChartColors = [
          {
            backgroundColor: 'rgb(102,163,255,1)',
          }
        ];
        this.freightLoading = false;
      }
    );
    this.surchargeLoading = true;
    this.apiService.makeStandardApiCall('values/GetSurchargeTotalByMonth', this.form).subscribe(
      (data: any) => {
        const chartData = appConstants.fun.parseChartDataFromApi(JSON.parse(data.Value), 'TotalSurcharge');

        // Set values to chart
        this.surchargeTotalChartLabels = chartData.labels;
        this.surchargeTotalChartData[0].data = chartData.values;
        this.surchargeTotalChartOptions.isLoading = false;
        this.surchargeTotalChartOptions.tooltips.callbacks;
        this.surchargeTotalChartColors = [
          {
            backgroundColor: 'rgba(255,255,255,0)',
            borderColor: 'rgba(102,163,255,1)', // Data drive this
            pointBackgroundColor: 'rgba(102,163,255,1)',
            pointBorderColor: 'rgba(102,163,255,0)',
          }
        ];
        this.surchargeLoading = false;
      }
    );
    this.costPackageLoading = true;
    this.apiService.makeStandardApiCall('values/GetCostPerPackageByMonth', this.form).subscribe(
      (data: any) => {
        const chartData = appConstants.fun.parseChartDataFromApi(JSON.parse(data.Value), 'Cost');

        // Set values to chart
        this.costPerPackageChartLabels = chartData.labels;
        this.costPerPackageChartData[0].data = chartData.values;
        this.costPerPackageChartOptions.isLoading = false;
        this.costPerPackageChartOptions.tooltips.callbacks;
        this.costPerPackageChartColors = [
          {
            backgroundColor: 'rgba(255,255,255,0)',
            borderColor: 'rgba(102,163,255,1)', // Data drive this
            pointBackgroundColor: 'rgba(102,163,255,1)',
            pointBorderColor: 'rgba(102,163,255,0)',
          }
        ];
        this.costPackageLoading = false;
      }
    );
    this.costPoundLoading = true;
    this.apiService.makeStandardApiCall('values/GetCostPerPoundByMonth', this.form).subscribe(
      (data: any) => {
        const chartData = appConstants.fun.parseChartDataFromApi(JSON.parse(data.Value), 'Cost');

        // Set values to chart
        this.costPerPoundChartLabels = chartData.labels;
        this.costPerPoundChartData = chartData.values;
        this.costPerPoundChartOptions.isLoading = false;
        this.costPerPoundChartOptions.tooltips.callbacks;
        this.costPerPoundChartColors = [
          {
            backgroundColor: 'rgb(102,163,255,1)',
          }
        ];
        this.costPoundLoading = false;
      }
    );
  }

  goToLink(link: string): void {
    const navigationDetails: string[] = [link];
    this.router.navigate(navigationDetails);
  }
}
