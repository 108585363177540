import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserInfo } from '../helpers/interfaces';
import { TokenStorageService } from '../services/token-storage.service';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-dashboard-menu',
  templateUrl: './dashboard-menu.component.html',
  styleUrls: ['./dashboard-menu.component.css']
})
export class DashboardMenuComponent implements OnInit {
  
  constructor(private router: Router, private tokenStorage: TokenStorageService, private userService: UserService) {
    this.tokenStorage.LoginStatus.subscribe(value => {
      this.isLoggedIn = tokenStorage.isLoggedIn;
    });
   }
  
  isLoggedIn!: boolean | false;
  showMenu!: boolean | false;
  userInfo!: UserInfo;

  ngOnInit(): void {
    this.isLoggedIn = this.tokenStorage.isLoggedIn;
    this.userInfo = this.userService.userInfo;
  }

  toggleShowMenu(): void {
    this.showMenu = !this.showMenu;
  }
  
  goToLink(link: string): void {
    const navigationDetails: string[] = [link];
    this.router.navigate(navigationDetails);
  }
  
  logout(): void {
    this.tokenStorage.signOut();
    const navigationDetails: string[] = ['/'];
    this.router.navigate(navigationDetails);
  }
}
