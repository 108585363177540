import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import * as fileSaver from 'file-saver';
import { AvoidableCharges, AvoidableChargesByWeek, ChartDataModel, StandardApiRequest, UserInfo } from '../helpers/interfaces';
import { ApiService } from '../services/api.service.service';
import { SessionService } from '../services/session.service';
import { UserService } from '../services/user.service';
import { ChartData, ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Color, BaseChartDirective, Label } from 'ng2-charts';
import { appConstants } from '../helpers/constants';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

export interface AvoidableElement {
  Description: string;
  Amount: number;
  Percentage: number;
  ReportLink: string;
}

@Component({
  selector: 'app-avoidable-cost',
  templateUrl: './avoidable-cost.component.html',
  styleUrls: ['./avoidable-cost.component.css']
})

export class AvoidableCostComponent implements OnInit {
  displayedColumns: string[] = ['Info', 'Description', 'Amount', 'ChargeCount', 'avoid-report'];
  totalAvoidable!: number | 0;
  totalPotential!: number | 0;
  totalAvoidableTable: MatTableDataSource<AvoidableElement> = new MatTableDataSource();
  avoidableCostsByWeek!: AvoidableChargesByWeek[];
  
  defaultScale = appConstants.fun.getChartScale('Week', 'Amount in Dollars');

  costChartUpdate: boolean = false;
  costChartData: ChartDataSets[] = [];
  costChartLabels: Label[] = [];
  costChartOptions: any = {
    responsive: true,
    scales: this.defaultScale,
    isLoading: true,
    tooltips: {
      enabled: true,
      mode: 'single',
      callbacks: {
        label: function (tooltipItems: { yLabel: string; }, data: any) {
          return ' $' + tooltipItems.yLabel;
        }
      }
    }
  };

  public costChartColors: Color[] = [
    { // blue
      backgroundColor: 'rgba(255,255,255,0)',
      borderColor: 'rgba(102,163,255,1)', // Data drive this
      pointBackgroundColor: 'rgba(102,163,255,1)',
      pointBorderColor: 'rgba(102,163,255,.8)',
    },
    { // purple
      backgroundColor: 'rgba(255,255,255,0)',
      borderColor: 'rgba(94, 91, 219, 01)', // Data drive this
      pointBackgroundColor: 'rgba(94, 91, 219, 1)',
      pointBorderColor: 'rgba(102,163,255,.8)',
    },
    { // grey
      backgroundColor: 'rgba(255,255,255,0)',
      borderColor: 'rgba(148,159,177,1)',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    },
    { // dark grey
      backgroundColor: 'rgba(255,255,255,0)',
      borderColor: 'rgba(77,83,96,1)',
      pointBackgroundColor: 'rgba(77,83,96,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(77,83,96,1)'
    },
    { // red
      backgroundColor: 'rgba(255,255,255,0)',
      borderColor: 'red',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    },
    { // green
      backgroundColor: 'rgba(255,255,255,0)',
      borderColor: 'rgba(84, 213, 21, 1)',
      pointBackgroundColor: 'rgba(84, 213, 21, .8)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    }
  ];
  costChartLegend: boolean = true;
  costChartType: any = appConstants.CHARTS.TYPES.LINE_CHART;

  userInfo!: UserInfo;
  dateToday: Date = new Date();
  form: StandardApiRequest = {
    StartDate: new Date(this.dateToday.getFullYear(), 0, 1),
    EndDate: new Date(this.dateToday),
    CarrierId: 0,
    AccountId: 0,
    ClientId: 0,
    IsPageLoad: false
  }
  costsUpdating: boolean = true;

  constructor(private apiService: ApiService, private router: Router, private sessionService: SessionService, private userService: UserService) { }

  ngOnInit(): void {
    this.userInfo = this.userService.userInfo;
    this.sessionService.avoidableCostForm.ClientId = this.userInfo.ClientId;
    this.sessionService.reportForm.ClientId = this.userInfo.ClientId;
    this.form = this.sessionService.avoidableCostForm;
    this.updateCosts();
  }

  @ViewChild(MatSort)
  sort!: MatSort;

  updateCosts(): void {
    this.sessionService.avoidableCostForm = this.form;
    this.costsUpdating = true;
    this.apiService.makeStandardApiCall('values/GetAvoidableCharges', this.form).subscribe(
      (data: any) => {
        if (data.StatusCode != 401) {
          this.totalAvoidable = 0;
          this.totalAvoidableTable = new MatTableDataSource<AvoidableElement>(JSON.parse(data.Value));
          this.totalAvoidableTable.data.forEach(element => {
            this.totalAvoidable += element.Amount;
          });
          this.totalAvoidableTable.sort = this.sort;
        }
        this.costsUpdating = false;
      }
    );
    this.costChartUpdate = true;
    this.apiService.makeStandardApiCall('values/GetAvoidableChargesByWeek', this.form).subscribe(
      (data: any) => {
        this.avoidableCostsByWeek = JSON.parse(data.Value);
        this.costChartData = [];
        if (this.avoidableCostsByWeek.length > 0){
          let labels: Label[] = [];
          let firstValue: boolean = true;
          let currentDesc: string = '';
          let currentData: any = { data: [], label: '' };

          this.avoidableCostsByWeek.forEach(element => {
            if (currentDesc == '') {
              currentData.label = element.Description;
              currentDesc = element.Description;
            }
            if (currentDesc != element.Description){
              this.costChartData.push(currentData);
              currentDesc = element.Description;
              currentData = { data: [], label: element.Description };
              firstValue = false;
            }
            if (firstValue) {
              labels.push(element.WeekDate + '-' + element.YearDate);
            }
            currentData.data.push(element.Amount);
          });
          this.costChartData.push(currentData);
          this.costChartLabels = labels;
          this.costChartOptions.isLoading = false;
          this.costChartOptions.tooltips.callbacks;
        }
        this.costChartUpdate = false;
      }
    )
  }


  goToReport(reportId: number, reportName: string): void {
    this.sessionService.reportForm.ReportId = reportId;
    this.sessionService.reportForm.StartDate = this.form.StartDate;
    this.sessionService.reportForm.EndDate = this.form.EndDate;
    this.sessionService.reportForm.CarrierId = 0;
    this.costsUpdating = true;
    //if (reportId === 10) {
    //  this.sessionService.reportForm.CarrierId = 2;
    //}
    this.apiService.downloadReportFile(this.sessionService.reportForm).subscribe((response: any) => { //when you use stricter type checking
      // const blob = new Blob([response], { type: 'application/octet-stream' });
      // const url= window.URL.createObjectURL(blob);
      // window.open(url);
      let blob: any = new Blob([response], { type: 'application/octet-stream' });
      const url = window.URL.createObjectURL(blob);
      if (reportId == 10)
        fileSaver.saveAs(blob, reportName + '.xls');
      else
        fileSaver.saveAs(blob, reportName + '.xlsx');
      this.costsUpdating = false;
    }), (error: any) => this.costsUpdating = false, console.log('Error downloading the file'), //when you use stricter type checking
      () => console.info('File downloaded successfully');
    const navigationDetails: string[] = ['reportDownload'];
  }
}
