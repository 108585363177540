

<form
      
name="form"
(ngSubmit)="f.form.valid && onSubmit()"
#f="ngForm"
novalidate
style="padding-left:50px;text-align: center;"
[ng2-loading]="attemptingLogin"
>

<div class="row">
  <h3>Forgot Password</h3>
</div>
<br/>
<div class="row">
    <h4>
        Enter your email to reset your password:
    </h4>
</div>
<div fxLayout="row">
<mat-form-field style="margin-left: 25%;" fxFlex="50%" fxFlex.xs="100%" fxFlex.md="75%" class="form-group">
  <mat-label for="username">Email</mat-label>
  <input
    matInput 
    type="text"
    class="form-control"
    name="username"
    [(ngModel)]="form.username"
    required
    #username="ngModel"
  />
  <mat-error
    class="alert alert-danger"
    role="alert"
    *ngIf="username.errors && f.submitted"
  >
    Email is required!
  </mat-error>
</mat-form-field>
</div>

<div fxLayout="row">
<div class="form-group" style="margin-left: 25%;" fxFlex="50%" fxFlex.xs="100%" fxFlex.md="75%">
  <button mat-raised-button style="float: right;">
    Submit
  </button>
</div>
</div>
</form>
