<form
      
      name="form"
      (ngSubmit)="f.form.valid && onSubmit()"
      #f="ngForm"
      novalidate
      style="padding-left:50px;text-align: center;"
      [ng2-loading]="attemptingLogin"
    >

    <div class="row">
        <h3>Password Reset</h3>
    </div>
    <div fxLayout="row">
      <mat-form-field style="margin-left: 25%;" fxFlex="50%" fxFlex.xs="100%" fxFlex.md="75%" class="form-group">
        <mat-label for="password">New Password</mat-label>
        <input
          matInput
          type="password"
          class="form-control"
          name="password"
          [(ngModel)]="form.password"
          required
          minlength="4"
          #password="ngModel"
        />
        <mat-error
          class="alert alert-danger"
          role="alert"
          *ngIf="password.errors && f.submitted"
        >
          <mat-error *ngIf="password.errors.required">Password is required</mat-error>
          <mat-error *ngIf="password.errors.minlength">
            Password must be at least 4 characters
          </mat-error>
        </mat-error>
      </mat-form-field>
    </div>
    <div fxLayout="row">
      <mat-form-field style="margin-left: 25%;" fxFlex="50%" fxFlex.xs="100%" fxFlex.md="75%" class="form-group">
        <mat-label for="password2">Confirm New Password</mat-label>
        <input
          matInput
          type="password"
          class="form-control"
          name="password2"
          [(ngModel)]="form.password2"
          required
          minlength="4"
          #password2="ngModel"
        />
        <mat-error
          class="alert alert-danger"
          role="alert"
          *ngIf="password2.errors && f.submitted"
        >
          <mat-error *ngIf="password2.errors.required">Confirm Password is required</mat-error>
          <mat-error *ngIf="password2.errors.minlength">
            Password must be at least 4 characters
          </mat-error>
        </mat-error>
      </mat-form-field>
    </div>
    
    <div style="padding:10px" class="form-group"  >
        <mat-error
          class="alert alert-danger"
          role="alert"
          *ngIf="f.submitted && isLoginFailed"
        >
          Login failed: {{ errorMessage }}
        </mat-error>
      </div>
      <div fxLayout="row">
      <div class="form-group" style="margin-left: 25%;" fxFlex="50%" fxFlex.xs="100%" fxFlex.md="75%">
        <button mat-raised-button style="float: right;">
          Submit
        </button>
      </div>
    </div>
    </form>