import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StandardApiRequest, UserInfo } from '../helpers/interfaces';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  //AUTH_API = 'https://localhost:44376/api/';
  AUTH_API = 'https://dashboardapi.lampreysystems.net/api/';
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(private http: HttpClient) { }

  makeStandardApiCall(endPoint: string, jsonInfo: any): Observable<any> {
    return this.http.post(this.AUTH_API + endPoint, jsonInfo, this.httpOptions);
  }

  makeStandardApiGet(endPoint: string): Observable<any> {
    return this.http.get(this.AUTH_API + endPoint);
  }

  updateUser(jsonInfo: UserInfo): Observable<any> {
    return this.http.post(this.AUTH_API + 'UpdateUserProfile', jsonInfo, this.httpOptions);
  }

  makeUserCase(username: string): Observable<any> {
    return this.http.post(this.AUTH_API + 'user/GetUserProfile', {
      "Username": username
    }, this.httpOptions);
  }

  downloadReportFile(jsonInfo: any): Observable<any> {
		return this.http.post(this.AUTH_API + 'values/GetReportDownload', jsonInfo, {responseType: 'blob'});
  }

  downloadClientInvoiceFile(jsonInfo: any): Observable<any> {
		return this.http.post(this.AUTH_API + 'values/DownloadClientVoice', jsonInfo, {responseType: 'blob'});
  }
  downloadCarrierInvoiceFile(jsonInfo: any): Observable<any> {
		return this.http.post(this.AUTH_API + 'values/DownloadCarrierInvoice', jsonInfo, {responseType: 'blob'});
  }
  
  downloadSearchResultsFile(jsonInfo: any): Observable<any> {
		return this.http.post(this.AUTH_API + 'values/GetSearchResultDownload', jsonInfo, {responseType: 'blob'});
  }

  downloadTrackingResultsFile(jsonInfo: any): Observable<any> {
		return this.http.post(this.AUTH_API + 'values/GetTrackingResultDownload', jsonInfo, {responseType: 'blob'});
  }
  
  downloadNetTransportation(jsonInfo: any): Observable<any> {
		return this.http.post(this.AUTH_API + 'values/GetNetTransportationDownload', jsonInfo, {responseType: 'blob'});
  }

  downloadSurchargeType(jsonInfo: any): Observable<any> {
		return this.http.post(this.AUTH_API + 'values/GetSurchargeTypeDownload', jsonInfo, {responseType: 'blob'});
  }

  downloadLayeredPackageByZone(jsonInfo: any): Observable<any> {
		return this.http.post(this.AUTH_API + 'values/GetPackageByZoneDownload', jsonInfo, {responseType: 'blob'});
  }

  downloadPackageCountByWeight(jsonInfo: any): Observable<any> {
		return this.http.post(this.AUTH_API + 'values/GetPackageByWeightDownload', jsonInfo, {responseType: 'blob'});
  }

  downloadFile(endpoint: string, jsonInfo: any): Observable<any> {
		return this.http.post(this.AUTH_API + 'values/' + endpoint, jsonInfo, {responseType: 'blob'});
  }
}
