import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  form: any = {
    password2: null,
    password: null,
    username: null,
  };
  isLoggedIn = false;
  isLoginFailed = false;
  errorMessage = '';
  roles: string[] = [];
  attemptingLogin: boolean = false;

  constructor(private route: ActivatedRoute, private authService: AuthService, private router: Router, private userService: UserService, private notification: MatSnackBar) { }

  resetToken: string = '';

  ngOnInit(): void {
    const routeParams = this.route.snapshot.paramMap;
    this.resetToken = String(routeParams.get('token'));
    if (this.resetToken === undefined || this.resetToken.length === 0)
    {
      this.notification.open("Invalid request to update password.");
      this.router.navigate(['/']);
    }
    this.authService.getUserByToken(this.resetToken).subscribe(data => {
      if (data.length === 0){
        this.form.notification("Invalid password reset token");
        this.router.navigate(['/']);
      } else {
        this.form.username = data;
    }
    });
  }

  onSubmit(): void {
    const { username, password } = this.form;
    if (this.form.password === this.form.password2) {
    this.attemptingLogin = true;
    this.isLoginFailed = false;
    this.errorMessage = "";
    this.authService.resetPassowrd(username, password, this.resetToken).subscribe(
      data => {
          if (data.StatusCode == 403 ){
            this.notification.open("There was an issue updating your password, please try again.");
            this.router.navigate(['/']);
          } else {
            this.notification.open("Password successfully updated.");
            this.router.navigate(['/']);
        }
        
      },
      err => {
        this.errorMessage = err.error.message;
        this.isLoginFailed = true;
        this.attemptingLogin = false;
      }
    );
    }
    else {
      this.errorMessage = "Password and Confirmation Password must match.";
      this.isLoginFailed = true;
      this.attemptingLogin = false;
    }
  }

  reloadPage(): void {
    window.location.reload();
  }
}
