import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { TokenStorageService } from '../services/token-storage.service';
import { Router } from '@angular/router';
import { UserService } from '../services/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  form: any = {
    username: null,
    password: null
  };
  isLoggedIn = false;
  isLoginFailed = false;
  errorMessage = '';
  roles: string[] = [];
  attemptingLogin: boolean = false;

  constructor(private authService: AuthService, private tokenStorage: TokenStorageService, private router: Router, private userService: UserService, private notification: MatSnackBar) { }

  ngOnInit(): void {
    if (this.tokenStorage.getToken()) {
      this.isLoggedIn = true;
      this.roles = this.tokenStorage.getUser().roles;
      this.router.navigate(['/dashboard']);
    }
  }

  onSubmit(): void {
    const { username, password } = this.form;
    this.attemptingLogin = true;
    this.authService.login(username, password).subscribe(
      data => {
          if (data.StatusCode == 401 ){
            this.errorMessage = "There was an issue logging in.  Please check your username/password combination and try again";
            this.isLoginFailed = true;    
            this.attemptingLogin = false;
          } else {
            this.tokenStorage.saveToken(data.Value);
            this.isLoginFailed = false;
            this.isLoggedIn = true;
            this.attemptingLogin = false;
            this.userService.getUserInfo(username);
        }
        
      },
      err => {
        this.errorMessage = err.error.message;
        this.isLoginFailed = true;
        this.attemptingLogin = false;
      }
    );
  }

  reloadPage(): void {
    window.location.reload();
  }
}