import { AfterViewInit, Component, ContentChild, ElementRef, OnInit, ViewChild } from '@angular/core';
import { TokenStorageService } from '../services/token-storage.service';
import { ChartType, ChartOptions, ChartDataSets } from 'chart.js';

import { ApiService } from '../services/api.service.service';
import { Account, Carrier, DashboardStats, NetCharge, PackagesByStatesFrom, PackagesByStatesTo, PackageCountByWeight, PackageCountByZone, StandardApiRequest, SurchargeByType, UserInfo } from '../helpers/interfaces';
import { UserService } from '../services/user.service';

import { MultiDataSet, Label } from 'ng2-charts';
import { MatRadioChange } from '@angular/material/radio';
import { ThemePalette } from '@angular/material/core';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';
import { SessionService } from '../services/session.service';
import { Router } from '@angular/router';

declare var google: any;

@Component({
  selector: 'app-dashboard-stats',
  templateUrl: './dashboard-stats.component.html',
  styleUrls: ['./dashboard-stats.component.css']
})
export class DashboardStatsComponent implements OnInit, AfterViewInit {

  @ViewChild('geoMapRef', { static: false })
  geoMapRef!: ElementRef;

  color: ThemePalette = 'primary';
  mode: ProgressSpinnerMode = 'determinate';

  map: string[] = ['Origin Location', 'Destination Location'];
  maplabels: string = '';

  transTypes: string[] = ['Dollar Amount', 'Package Count'];
  transLabels: string = '';
  
  marked = false;

  surchargeOptions: ChartOptions = {
    responsive: true,

  };

  netChargeOptions: ChartOptions = {
    responsive: true,
    scales: {
      xAxes: [{
        scaleLabel: {
          display: true,
          labelString: 'Amount in dollars'
        }
      }]
    }, tooltips: {
      enabled: true,
      mode: 'single',
      callbacks: {
        label: function (tooltipItems, data) {
          return ' $' + tooltipItems.xLabel;
        }
      }
    }
  }

  netChargeLabels: Label[] = [];
  netChargeType: ChartType = 'horizontalBar';
  netChargeLegend = false;
  netChargePlugins = [];
  netChargeColors = [
    {
      backgroundColor: 'rgb(102,163,255,1)',
    }
   ];
  netChargeChartData: ChartDataSets[] = [
  ];

  surchargeLabels: Label[] = [];
  surchargeData: MultiDataSet = [
    [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
  ];
  doughnutChartType: ChartType = 'doughnut';

  basicOptions: any;
  constructor(private apiService: ApiService, private router: Router, private sessionService: SessionService, private userService: UserService) { }

  dateToday: Date = new Date();
  form: StandardApiRequest = {
    StartDate: new Date(new Date(Date.now() - 14 * 24 * 60 * 60 * 1000)),
    EndDate: new Date(this.dateToday),
    CarrierId: 0,
    AccountId: 0,
    ClientId: 0,
    IsPageLoad: false
  }

  userInfo!: UserInfo;
  apiData: StandardApiRequest = {
    StartDate: new Date(new Date(Date.now() - 14 * 24 * 60 * 60 * 1000)),
    EndDate: new Date(this.dateToday),
    CarrierId: 0,
    AccountId: 0,
    ClientId: 0,
    IsPageLoad: false
  };
  dashboardStats: DashboardStats = {
    StartDate: this.form.StartDate,
    EndDate: this.form.EndDate,
    AverageZone_Profit: 0,
    AverageZone_Amount: 0,
    FreightSpend_FedexEffectiveDiscount: 0,
    FreightSpend_UPSEffectiveDiscount: 0,
    FreightSpend_Profit: 0,
    FreightSpend_Count: 0,
    FreightSpend_Amount: 0,
    Surcharges_Profit: 0,
    Surcharges_Count: 0,
    Surcharges_Amount: 0,
    NetSpend_Profit: 0,
    NetSpend_Count: 0,
    NetSpend_Amount: 0,
    AccountId: 0,
    CarrierId: 0,
    WeightCostPerPackage: 0

  };
  surchargesByType: SurchargeByType[] = []
  packageCountByWeight: PackageCountByWeight[] = []
  packageCountByZone: PackageCountByZone[] = []
  packagesByStatesTo: PackagesByStatesTo[] = []
  packagesByStatesFrom: PackagesByStatesFrom[] = []
  netChargeData!: NetCharge[] | [];

  weightChartOptions: any = {
    scaleShowVerticalLines: false,
    responsive: true,
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: true
        },
        scaleLabel: {
          display: true,
          labelString: 'Package Count'
        }
      }],
      xAxes: [{
        ticks: {
          
        },
        scaleLabel: {
          display: true,
          labelString: 'Weight'
        }
      }]
    }
  };
  weightChartLabels: string[] = [];
  weightChartType: ChartType = 'bar';
  weightChartLegend: boolean = true;
  weightChartData: ChartDataSets[] = [];
  weightChartLoading: boolean = true;

  layeredPackagesByZonebarChartOptions: any = {
    scaleShowVerticalLines: false,
    responsive: true,
    loading: true,
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: true
        },
        scaleLabel: {
          display: true,
          labelString: 'Package Count'
        }
      }],
      xAxes: [{
        ticks: {
          
        },
        scaleLabel: {
          display: true,
          labelString: 'Zone'
        }
      }]
    }
  };
  layeredPackagesByZonebarChartLabels: string[] = [];
  layeredPackagesByZonebarChartType: ChartType = 'bar';
  layeredPackagesByZonebarChartLegend: boolean = true;

  layeredPackagesByZonebarChartData: any[] = [];
  layeredPackagesByZonebarChartColors: Array<any> = [ // TODO get colors from common area
    { backgroundColor: 'rgb(255, 161, 181)' },
    { backgroundColor: 'rgb(134, 199, 243)' },
    { backgroundColor: 'rgb(255, 226, 154)' },
    { backgroundColor: 'rgb(147, 217, 217)' },
    { backgroundColor: 'rgb(147, 145, 118)' },
    { backgroundColor: 'rgb(107, 51, 34)' },
    { backgroundColor: 'rgb(145, 150, 43)' },
    { backgroundColor: 'rgb(27, 67, 71)' },
    { backgroundColor: 'rgb(38, 38, 37)' },
    { backgroundColor: 'rgb(94, 201, 174)' }
  ];

  Carriers: Carrier[] = [{ CarrierId: 0, CarrierName: "All" }]
  Accounts: Account[] = [{ AccountId: 0, AccountNumber: "All" }]
  surchargesLoading: boolean = true;
  netChargeLoading: boolean = true;
  packageCountLoading: boolean = true;
  layeredPackageLoading: boolean = true;
  mapLoading: boolean = true;
  statsLoading: boolean = true;

  ngAfterViewInit() {


  }

  ngOnInit(): void {
    this.userInfo = this.userService.userInfo;
    this.sessionService.dashboardStatForm.ClientId = this.userInfo.ClientId;
    this.form = this.sessionService.dashboardStatForm;
    
    this.apiData.ClientId = this.userInfo.ClientId;
    this.map = ['Origin Location', 'Destination Location'];
    this.apiService.makeStandardApiCall('user/GetCarriersByClient', this.apiData).subscribe(
      (data: any) => {
        if (data.StatusCode != 401) {
          const carriers: Carrier[] = JSON.parse(data.Value);
          this.Carriers = [];
          this.Carriers.push({ CarrierName: 'All', CarrierId: 0 });
          carriers.forEach(element => {
            this.Carriers.push(element);
          });
        }
      }
    );
    this.updateDashboardComponents();
    this.updateAccountsList();
    this.transLabels = "Dollar Amount"
  }

  onSubmit(): void {
    this.updateDashboardComponents();
  }

  updateAccountsList(): void {
    this.apiService.makeStandardApiCall('user/GetAccountsByClientCarrier', this.form).subscribe(
      (data: any) => {
        if (data.StatusCode != 401) {
          const accounts: Account[] = JSON.parse(data.Value);
          this.Accounts = [];
          this.Accounts.push({ AccountNumber: 'All', AccountId: 0 });
          accounts.forEach(element => {
            this.Accounts.push({ AccountNumber: element.AccountNumber.trim().toUpperCase(), AccountId: element.AccountId });
          });
        }
      }
    );
  }

  updateDashboardComponents(): void {
    this.apiData = {
      AccountId: this.form.AccountId,
      CarrierId: this.form.CarrierId,
      ClientId: this.userInfo.ClientId,
      StartDate: this.form.StartDate,
      EndDate: this.form.EndDate,
      IsPageLoad: false
    };

    this.sessionService.dashboardStatForm = this.form;

    this.statsLoading = true;
    this.apiService.makeStandardApiCall('values/GetDashboardStats', this.apiData).subscribe(
      (data: any) => {
        if (data.StatusCode != 401) {
          this.dashboardStats = JSON.parse(data.Value);
          this.statsLoading = false;
        }
      }
    );
    
    this.getNetByDollar();

    this.getSurchargeByDollar();

    this.packageCountLoading = true;
    this.apiService.makeStandardApiCall('values/GetPackageCountByWeight', this.apiData).subscribe(
      (data: any) => {
        if (data.StatusCode != 401) {
          this.packageCountByWeight = JSON.parse(data.Value);
          const labels: string[] = [];
          const fedexData: any = [];
          const upsData: any = [];

          // Temporary hold data
          this.packageCountByWeight.forEach(item => {
            if ((this.form.CarrierId === 2) && (item.CarrierId !== 2)) {
              return;
            }
            if ((this.form.CarrierId === 1) && (item.CarrierId !== 1)) {
              return;
            }

            if (labels.indexOf(item.WeightBucketName) === -1) {

              labels.push(item.WeightBucketName);
            }

            if (item.CarrierId === 1) {
              fedexData[item.WeightBucketName] = item.PackageCount;
            }

            if (item.CarrierId === 2) {
              upsData[item.WeightBucketName] = item.PackageCount;
            }
          });

          const fedexChartData: any = {
            data: [],
            label: ['FEDEX'],
          };

          const upsChartData: any = {
            data: [],
            label: ['UPS'],
          };

          // Organise data for chart
          labels.forEach((item) => {
            if (fedexData[item]) {
              fedexChartData.data.push(fedexData[item]);
            } else {
              fedexChartData.data.push(0);
            }

            if (upsData[item]) {
              upsChartData.data.push(upsData[item]);
            } else {
              upsChartData.data.push(0);
            }
          });

          // Finally set chart data
          this.weightChartLabels = labels;

          // Empty data before pushing
          this.weightChartData = [];

          // Add labels according to labels
          switch (this.form.CarrierId) {
            case 0:
              this.weightChartData.push(fedexChartData);
              this.weightChartData.push(upsChartData);
              break;
            case 1:
              this.weightChartData.push(fedexChartData);
              break;
            case 2:
              this.weightChartData.push(upsChartData);
              break;
          }
          this.packageCountLoading = false;
        }
      }
    );
    this.layeredPackageLoading = true;
    this.apiService.makeStandardApiCall('values/GetLayeredPackagesByZone', this.apiData).subscribe(
      (data: any) => {
        if (data.StatusCode != 401) {
          this.packageCountByZone = JSON.parse(data.Value);
          const paresdArr: { data: number[], label: string }[] = [];
          const chartLabel: Label = [];
          this.packageCountByZone.forEach(
            (result) => {
              // Flag for element found in object
              let found = false;

              // Iterate parsed data and append data accordingly
              paresdArr.forEach((item) => {
                if (item.label === result.ServiceGroupName) {
                  found = true;
                }
              });

              // If not found create new entry
              if (!found) {
                paresdArr.push({ data: [], label: result.ServiceGroupName });
              }

            }
          );

          this.packageCountByZone.forEach(
            (result) => {
              if ((chartLabel.indexOf(result.ZoneSN.toString()) === -1)) {
                chartLabel.push(result.ZoneSN.toString());
              }
            }
          );

          chartLabel.forEach((chartItem) => {
            paresdArr.forEach((groupItem) => {
              let found = false;
              let value = 0;
              this.packageCountByZone.forEach(
                (result) => {
                  // Flag for element found in object
                  let found = false;

                  // Iterate parsed data and append data accordingly

                  if (groupItem.label === result.ServiceGroupName && chartItem === result.ZoneSN.toString()) {
                    value = result.PackageCount;
                    found = true;
                  }


                }

              );

              groupItem.data.push(value);

            });
          });

          // Add chart labels

          // If no data from api set default data to display empty chart
          if (paresdArr.length === 0) {
            paresdArr.push({ data: [], label: 'No data' });
          }

          //// Construct labels
          //for (let i = 1; i <= paresdArr[0].data.length; i++) {
          //	chartLabel.push(i.toString());
          //}

          this.layeredPackagesByZonebarChartLabels = chartLabel;
          this.layeredPackagesByZonebarChartData = paresdArr;
          this.layeredPackagesByZonebarChartOptions.loading = false;
        }
        this.layeredPackageLoading = false;
      }
    );

    this.maplabels = 'Destination Location';
    this.getPackagesByStateTo();
  }

  getNetByDollar(): void {
    this.netChargeLoading = true;
    this.netChargeOptions = {
      responsive: true,
      scales: {
        xAxes: [{
          scaleLabel: {
            display: true,
            labelString: 'Amount in dollars'
          }
        }]
      }, tooltips: {
        enabled: true,
        mode: 'single',
        callbacks: {
          label: function (tooltipItems, data) {
            return ' $' + tooltipItems.xLabel;
          }
        }
      }
    }
    this.apiService.makeStandardApiCall('values/GetNetChargeByServices', this.apiData).subscribe(
      (data: any) => {
        if (data.StatusCode != 401) {
          this.netChargeData = JSON.parse(data.Value);
          // Temp variables
          const labelsTemp: Label[] = [];
          const valuesTemp: any = [];

          // Populate
          this.netChargeData.forEach(x => {
            labelsTemp.push(x.ServiceGroupName);
            valuesTemp.push(x.NetFreight);
          });

          // Set values to chart
          this.netChargeLabels = labelsTemp;
          this.netChargeChartData[0].data = valuesTemp;
        }
        this.netChargeLoading = false;
      }
    );
  }

  getNetByCount(): void {
    this.netChargeLoading = true;
    this.netChargeOptions = {
      responsive: true,
      scales: {
        xAxes: [{
          scaleLabel: {
            display: true,
            labelString: 'Package Count'
          }
        }]
      }, tooltips: {
        enabled: true,
        mode: 'single',
        callbacks: {
          label: function (tooltipItems, data) {
            return ' $' + tooltipItems.xLabel;
          }
        }
      }
    }
    this.apiService.makeStandardApiCall('values/GetNetChargeByServices', this.apiData).subscribe(
      (data: any) => {
        if (data.StatusCode != 401) {
          this.netChargeData = JSON.parse(data.Value);
          // Temp variables
          const labelsTemp: Label[] = [];
          const valuesTemp: any = [];

          // Populate
          this.netChargeData.forEach(x => {
            labelsTemp.push(x.ServiceGroupName);
            valuesTemp.push(x.PackageCount);
          });

          // Set values to chart
          this.netChargeLabels = labelsTemp;
          this.netChargeChartData[0].data = valuesTemp;
        }
        this.netChargeLoading = false;
      }
    );
  }

  getSurchargeByDollar(): void {
    this.surchargesLoading = true;
    this.apiService.makeStandardApiCall('values/GetSurchargeByTypes', this.apiData).subscribe(
      (data: any) => {
        if (data.StatusCode != 401) {
          this.surchargesByType = JSON.parse(data.Value);
          const surchargeLabelsTemp: Label[] = [];
          const surchargeDataTemp: MultiDataSet = [
            []
          ];
          this.surchargesByType.forEach(element => {
            surchargeLabelsTemp.push(element.ServiceGroupName);
            surchargeDataTemp[0].push(element.NetCharge);
          });
          this.surchargeLabels = surchargeLabelsTemp;
          this.surchargeData = surchargeDataTemp;
        }
        this.surchargesLoading = false;
      }
    );
  }

  getSurchargeByCount(): void {
    this.surchargesLoading = true;
    this.apiService.makeStandardApiCall('values/GetSurchargeByTypes', this.apiData).subscribe(
      (data: any) => {
        if (data.StatusCode != 401) {
          this.surchargesByType = JSON.parse(data.Value);
          const surchargeLabelsTemp: Label[] = [];
          const surchargeDataTemp: MultiDataSet = [
            []
          ];
          this.surchargesByType.forEach(element => {
            surchargeLabelsTemp.push(element.ServiceGroupName);
            surchargeDataTemp[0].push(element.NetCharge);
          });
          this.surchargeLabels = surchargeLabelsTemp;
          this.surchargeData = surchargeDataTemp;
        }
        this.surchargesLoading = false;
      }
    );
  }

  getPackagesByStateTo(): void {
    this.mapLoading = true;
    this.apiService.makeStandardApiCall('values/GetPackagesByStatesTo', this.apiData).subscribe(
      (data: any) => {
        if (data.StatusCode != 401) {
          this.packagesByStatesTo = JSON.parse(data.Value);

          // Parse data
          const mapData: (string | number)[][] = [];
          mapData.push(['state', 'Packages']);
          this.packagesByStatesTo.forEach(function (item) {
            if (item.RecipState !== null)
              mapData.push([item.RecipState.toUpperCase(), item.Packages]);
          });

          // Init geo chart
          this.initGeoChart(mapData);

        }
        this.mapLoading = false;
      }
    );
  }

  getPackagesByStateFrom(): void {
    this.mapLoading = true;
    this.apiService.makeStandardApiCall('values/GetPackagesByStatesFrom', this.apiData).subscribe(
      (data: any) => {
        if (data.StatusCode != 401) {
          this.packagesByStatesFrom = JSON.parse(data.Value);

          // Parse data
          const mapData: (string | number)[][] = [];
          mapData.push(['state', 'Packages']);
          this.packagesByStatesFrom.forEach(function (item) {
            if (item.ShipperState !== null)
              mapData.push([item.ShipperState.toUpperCase(), item.Packages]);
          });

          // Init geo chart
          this.initGeoChart(mapData);

        }
        this.mapLoading = false;
      }
    );
  }
  /**
     * Load geo chart api
     */
  loadGeoApi() {
    // Load google charts
    google.charts.load('current', {
      'packages': ['geochart'],
      // Note: you will need to get a mapsApiKey for your project.
      // See: https://developers.google.com/chart/interactive/docs/basic_load_libs#load-settings
      'mapsApiKey': 'AIzaSyD-9tSrke72PouQMnMX-a7eZSW0jkFMBWY'
    });
  }

  /**
   * Initialize geo chart
   * @param res Raw data for packages by states
   */
  initGeoChart(data: any) {
    const ele = this.geoMapRef.nativeElement;
    google.charts.setOnLoadCallback(function () {
      const sourceData = google.visualization.arrayToDataTable(data);
      const options = {
        region: 'US',
        resolution: 'provinces',
        colorAxis: { colors: ['#cbd6d0', '#49bc7a'] },
      };
      const chart = new google.visualization.GeoChart(ele);
      chart.draw(sourceData, options);
    });
  }

  toggleVisibility(event: MatRadioChange) {
    this.marked = event.source.checked;
    if (event.source.value == 'Destination Location') {
      this.getPackagesByStateTo();
    }
    else {
      this.getPackagesByStateFrom();
    }
  }

  toggleTransType(event: MatRadioChange) {
    this.marked = event.source.checked;
    if (event.source.value == 'Dollar Amount') {
      this.getNetByDollar();
    }
    else {
      this.getNetByCount();
    }
  }

  goToLink(link: string): void {
    const navigationDetails: string[] = [link];
    this.router.navigate(navigationDetails);
  }
}
