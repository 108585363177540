import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './login/login.component';
import { DashboardStatsComponent } from './dashboard-stats/dashboard-stats.component';
import { AvoidableCostComponent } from './avoidable-cost/avoidable-cost.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { ReportDownloadComponent } from './report-download/report-download.component';
import { MonthlyInvoicesComponent } from './monthly-invoices/monthly-invoices.component';
import { TrendsComponent } from './trends/trends.component';
import { PerformanceComponent } from './performance/performance.component';
import { PackageSearchComponent } from './package-search/package-search.component';
import { CarrierInvoicesComponent } from './carrier-invoices/carrier-invoices.component';
import { AirbillDetailsComponent } from './airbill-details/airbill-details.component';
import { NetTransChartComponent } from './net-trans-chart/net-trans-chart.component';
import { SurchargeTypeChartComponent } from './surcharge-type-chart/surcharge-type-chart.component';
import { CountWeightChartComponent } from './count-weight-chart/count-weight-chart.component';
import { PackagesWeightChartComponent } from './packages-weight-chart/packages-weight-chart.component';
import { CostPoundChartComponent } from './cost-pound-chart/cost-pound-chart.component';
import { CostPackageChartComponent } from './cost-package-chart/cost-package-chart.component';
import { SurchargeMonthChartComponent } from './surcharge-month-chart/surcharge-month-chart.component';
import { TotalFreightMonthChartComponent } from './total-freight-month-chart/total-freight-month-chart.component';
import { TotalNetMonthChartComponent } from './total-net-month-chart/total-net-month-chart.component';
import { TotalWeightMonthChartComponent } from './total-weight-month-chart/total-weight-month-chart.component';
import { PerformanceByServiceChartComponent } from './performance-by-service-chart/performance-by-service-chart.component';
import { RefundsByMonthChartComponent } from './refunds-by-month-chart/refunds-by-month-chart.component';
import { RefundsTotalChartComponent } from './refunds-total-chart/refunds-total-chart.component';
import { TrackingExceptionsChartComponent } from './tracking-exceptions-chart/tracking-exceptions-chart.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { AddClientComponent } from './add-client/add-client.component';

const routes: Routes = [
  { path: 'dashboard', component: DashboardStatsComponent },
  { path: 'login', component: LoginComponent },
  { path: 'avoidable', component: AvoidableCostComponent },
  { path: 'profile', component: UserProfileComponent },
  { path: 'reportDownload', component: ReportDownloadComponent },
  { path: 'invoices', component: MonthlyInvoicesComponent },
  { path: 'trends', component: TrendsComponent },
  { path: 'performance', component: PerformanceComponent },
  { path: 'search', component: PackageSearchComponent }, 
  { path: 'carrierInvoice', component: CarrierInvoicesComponent }, 
  { path: 'airbillDetails/:airbillId', component: AirbillDetailsComponent }, 
  { path: 'nettransbyservice', component: NetTransChartComponent },
  { path: 'surchargebytype', component: SurchargeTypeChartComponent },
  { path: 'countbyweightgroup', component: CountWeightChartComponent },
  { path: 'packagesbyzone', component: PackagesWeightChartComponent },
  { path: 'costbypound', component: CostPoundChartComponent },
  { path: 'costbypackage', component: CostPackageChartComponent },
  { path: 'surchargebymonth', component: SurchargeMonthChartComponent },
  { path: 'totalfreightbymonth', component: TotalFreightMonthChartComponent },
  { path: 'totalweightbymonth', component: TotalWeightMonthChartComponent },
  { path: 'totalnetbymonth', component: TotalNetMonthChartComponent },
  { path: 'refundsbymonth', component: RefundsByMonthChartComponent },
  { path: 'refundstotal', component: RefundsTotalChartComponent },
  { path: 'performancebyservice', component: PerformanceByServiceChartComponent },
  { path: 'trackingexceptions', component: TrackingExceptionsChartComponent },
  { path: 'forgotpassword', component: ForgotPasswordComponent },
  { path: 'addnew', component: AddClientComponent },
  { path: 'resetpassword/:token', component: ResetPasswordComponent }, 
  { path: '', redirectTo: 'login', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }